/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import {
  Input,
  Tooltip,
  Table,
  Checkbox,
  Button,
  Form,
  Modal,
  Drawer,
  message,
  Row,
  Col,
  Skeleton,
} from "antd";
import moment from "moment";
import { useSelector } from "react-redux";
import {
  SearchOutlined,
  EditOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import {
  editTuchPointData,
  getTuchPointIdData,
  tresableLinkData,
  getCustomerId,
  getContacts,
  testJourneyTouchpoint,
  fuzzySearch,
  getContactbyID,
} from "../../apiService/RESTApi";
import successIcon from "../../assets/Images/successIcon.svg";
import AnalyticsIcon from "../../assets/Images/analytics.svg";

const { TextArea, Search } = Input;
export default function TableView(props) {
  let debounceValue = "";
  const [edit, setEdit] = useState(false);
  const [form] = Form.useForm();
  const [touchPointForm, setTouchPointForm] = useState({});
  const [touchPointId, setTouchPointId] = useState();
  const [tableData, setTableData] = useState([]);
  const { groups, roAdmin } = useSelector((state) => state.loginReducer);
  const [loading, setLoading] = useState(false);
  const [touchpointLoading, setTouchpointLoading] = useState(false);
  const [updateData, setUpdateData] = useState([]);
  const [open, setOpen] = useState(false);
  const [visible, setVisible] = useState(false);
  const [testJourneyId, setTestJourneyId] = useState("");
  const [testTouchPointId, setTestTouchPointId] = useState("");
  const [allContacts, setAllContacts] = useState([]);
  const [searchedContacts, setSearchedContacts] = useState([]);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [journeyIdForSelectedTouchPoint, setJourneyIdForSelectedTouchPoint] =
    useState("");
  const [fuzzySearchLoader, setFuzzySearchLoader] = useState(false);

  useEffect(() => {
    const response = getContacts(groups[0]);
    response.then((data) => {
      //need to remove the filter as all the contacts have compulsory phone number field.
      const filteredContacts = data?.response?.data?.filter(
        (contact) => contact?.primaryPhoneNumber
      );
      const contactList = filteredContacts?.map((contact) => {
        return {
          label: `${contact?.firstName} ${contact?.lastName}`,
          value: contact?.contactId,
        };
      });
      setAllContacts(contactList);
      setSearchedContacts(contactList);
    });
  }, []);

  const renderContent = (value, row, index) => {
    const obj = {
      children: value,
      props: {},
    };
    if (row?.name === true) {
      obj.props.colSpan = 0;
    }
    return obj;
  };

  const columns = [
    {
      title: "Touchpoint Name",
      dataIndex: "Tname",
      key: "Tname",
      className: "touchpoint-name ",
      render: (text, row, index) => {
        if (row?.name === true) {
          return {
            children: (
              <div className="name-wrapper p-16">
                <span style={{ color: "#124173", fontWeight: 600 }}>
                  {text}
                </span>
              </div>
            ),
            props: {
              colSpan: 5,
            },
          };
        }
        return <span className="p-16 d-block">{text}</span>;
      },
    },

    {
      title: "Trigger/Timing",
      dataIndex: "type",
      key: "type",
      className: "p-16",
      render: renderContent,
    },

    {
      title: "Message",
      key: "msg",
      dataIndex: "msg",
      className: "p-16",
      render: renderContent,
    },
    {
      title: "Links",
      key: "link",
      dataIndex: "link",
      className: "p-16",
      render: renderContent,
    },
    {
      title: "Edit",
      dataIndex: "edit",
      key: "edit",
      width: 80,
      className: "p-16",
      render: renderContent,
    },
  ];

  const handleEdit = (data, item) => {
    let temp = { ...touchPointForm };
    temp["id"] = data?.id !== undefined && data?.id;
    temp["wait"] =
      data?.wait !== undefined && (data?.wait || "Immediate on insert");
    temp["name"] = data?.name !== undefined && data?.name;
    temp["messageContent"] =
      data?.messageContent !== undefined && data?.messageContent;
    temp["messageTemplateName"] =
      data?.messageTemplateName !== undefined && data?.messageTemplateName;
    temp["contentLinkToTrackableLink"] =
      data?.contentLinkToTrackableLink !== undefined &&
      data?.contentLinkToTrackableLink !== null
        ? Object.keys(data?.contentLinkToTrackableLink)
        : "";
    setTouchPointId(data?.id);
    setJourneyIdForSelectedTouchPoint(item?.id);
    form.setFieldsValue(temp);
    setTouchPointForm(temp);

    setEdit(true);
  };

  const onClose = () => {
    setEdit(false);
  };

  //Update touchpoint details
  const handleUpdate = async () => {
    setTouchpointLoading(true);
    let temp = { ...touchPointForm };
    let formData = form.getFieldsValue();
    for (let item in formData) {
      temp[item] = formData[item];
    }

    setTouchPointForm(temp);
    let res = await getCustomerId(groups[0]);
    let customerId = res.response?.data?.[0]?.customerId;
    await tresableLinkData(
      customerId,
      encodeURIComponent(formData?.contentLinkToTrackableLink),
      touchPointId
    )
      .then((res) => {
        if (
          temp?.contentLinkToTrackableLink !== null &&
          temp?.contentLinkToTrackableLink !== ""
        ) {
          temp["linkCode"] = res?.response?.data?.[0]?.[0];
        } else {
          delete temp.linkCode;
        }
        delete temp?.contentLinkToTrackableLink;

        editTuchPointData(
          groups[0],
          journeyIdForSelectedTouchPoint,
          touchPointId,
          temp
        )
          .then(() => {
            setUpdateData(temp);
            getTableIdData(updateData);
            setEdit(false);
            setTouchpointLoading(false);
          })
          .catch((error) => {
            message.error("Unable to edit touchpoint");

            setEdit(false);
            setTouchpointLoading(false);
          });
      })
      .catch((error) => {
        message.error("Unable to edit touchpoint");
        setEdit(false);
        setTouchpointLoading(false);
      });
  };

  const getTableIdData = async () => {
    if (props.journeyId !== undefined) {
      setLoading(true);

      await getTuchPointIdData(groups[0], props?.journeyId)
        .then((res) => {
          setUpdateData(res);
          let temp = [];
          Promise.all(
            res?.response?.data?.map((item, index) => {
              temp.push({ Tname: item?.name, name: true });
              item?.touchpoints?.map((value) => {
                return temp.push({
                  key: value?.id !== undefined ? value.id : "",
                  journeyId: item.id,
                  Tname: (
                    <>
                      <p>
                        {value?.messageTemplateName !== undefined
                          ? value?.messageTemplateName
                          : ""}
                      </p>
                      <button
                        disabled={roAdmin}
                        style={{
                          textDecoration: "underline",
                          fontStyle: "italic",
                          color: "#F37064",
                          cursor: "pointer",
                          border: "none",
                          background: "none",
                        }}
                        onClick={() => showModal(item?.id, value.id)}
                      >
                        Test now
                      </button>
                    </>
                  ),
                  edit: (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      {window.location.hostname !== "app.blustream.io" ? (
                        <Button
                          disabled={roAdmin}
                          type="text"
                          style={{ color: "#959595" }}
                        >
                          <img
                            src={AnalyticsIcon}
                            alt=""
                            style={{ width: "17px", height: "20px" }}
                          />
                        </Button>
                      ) : (
                        ""
                      )}
                      <Button
                        disabled={roAdmin}
                        type="text"
                        style={{ color: "#959595" }}
                        onClick={() => handleEdit(value, item)}
                      >
                        <EditOutlined style={{ fontSize: "20px" }} />
                      </Button>
                    </div>
                  ),
                  type: (
                    <p>
                      {value?.wait !== undefined &&
                      value?.wait !== null &&
                      value.wait !== ""
                        ? moment.duration(value?.wait).asDays() >= 1
                          ? `After ${moment
                              .duration(value?.wait)
                              .asDays()} days to previous`
                          : `After ${moment
                              .duration(value?.wait)
                              .asHours()} hours to previous`
                        : "Immediate on insert"}
                    </p>
                  ),
                  msg:
                    value?.messageContent !== undefined ? (
                      <pre
                        style={{
                          whiteSpace: "break-spaces",
                          fontFamily: "Hellix",
                        }}
                      >
                        {value?.messageContent}
                      </pre>
                    ) : (
                      ""
                    ),
                  link:
                    value?.contentLinkToTrackableLink !== undefined &&
                    value?.contentLinkToTrackableLink !== null ? (
                      <a
                        href={Object.keys(value?.contentLinkToTrackableLink)}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {Object.keys(value?.contentLinkToTrackableLink)}
                      </a>
                    ) : (
                      ""
                    ),
                });
              });
            })
          )
            .then((res) => {
              setTableData(temp);
              setLoading(false);
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
            });
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    getTableIdData();
  }, [props?.journeyId]);

  const handleOk = async () => {
    setOpen(false);
    setSelectedContacts([]);
    setLoading(true);
    selectedContacts.map((contactId) => {
      const response = testJourneyTouchpoint(contactId, testJourneyId, testTouchPointId);
      return response;
    });
    setLoading(false);
  };

  const handleCancel = () => {
    setOpen(false);
    setSelectedContacts([]);
  };

  const showModal = (journeyId, touchpointId) => {
    setOpen(true);
    setTestJourneyId(journeyId);
    setTestTouchPointId(touchpointId);
  };

  const checkedContacts = (checkedValue) => {
    let contacts;
    if (selectedContacts?.includes(checkedValue)) {
      contacts = selectedContacts?.filter((id) => id !== checkedValue);
    } else {
      contacts = [...selectedContacts, checkedValue];
    }
    setSelectedContacts(contacts);
  };

  const SearchContact = (value) => {
    if (value !== "") {
      clearTimeout(debounceValue);
      debounceValue = setTimeout(async () => {
        setFuzzySearchLoader(true);
        await getCustomerId(groups[0]).then(async (res) => {
          const customerArn = res.response.data[0].customerArn;
          const searchResults = await fuzzySearch(customerArn, value);
          const promiseArray = searchResults?.data?.map((contact) => {
            return getContactbyID(contact?.contactId);
          });
          Promise.all(promiseArray).then((values) => {
            //need to remove the filter as all the contacts have compulsory phone number field.
            const filteredContacts = values?.filter(
              (contact) => contact?.primaryPhoneNumber
            );
            const contactList = filteredContacts?.map((contact) => {
              return {
                label: `${contact?.firstName} ${contact?.lastName}`,
                value: contact?.contactId,
              };
            });

            setSearchedContacts(contactList);
            setFuzzySearchLoader(false);
          });
        });
      }, 1000);
    } else {
      clearTimeout(debounceValue);
      setSearchedContacts(allContacts);
    }
  };

  return (
    <>
      <div className="tableView">
        <Table
          className="journey-table"
          columns={columns}
          loading={loading}
          pagination={false}
          tableLayout="fixed"
          bordered
          dataSource={tableData}
          scroll={{ y: 450 }}
        />
        <Drawer
          width={430}
          title="Edit touchpoint details"
          placement="right"
          onClose={onClose}
          visible={edit}
        >
          <Form
            form={form}
            layout="vertical"
            className="edit-touchpoint-form"
            style={{ paddingBottom: 60 }}
            onFinish={handleUpdate}
          >
            <Form.Item
              label="Touchpoint Name"
              style={{ width: "100%" }}
              name="messageTemplateName"
            >
              <Input
                className="standard-input"
                disabled={true}
                placeholder="Enter Touchpoint Name"
              />
            </Form.Item>
            <Form.Item
              label="Trigger/Timing"
              style={{ width: "100%" }}
              name="wait"
            >
              <Input className="standard-input" disabled={true} />
            </Form.Item>
            <Form.Item
              label={
                <Tooltip
                  placement="rightBottom"
                  title="To insert the link from the field below into the message, add {{LINK}} to the message’s text."
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div>Message</div>
                    <InfoCircleOutlined style={{ paddingLeft: "3px" }} />
                  </div>
                </Tooltip>
              }
              style={{ width: "100%" }}
              name="messageContent"
              type="textarea"
            >
              <TextArea
                autoSize={{ minRows: 2, maxRows: 6 }}
                placeholder="Enter Message"
              />
            </Form.Item>
            <Form.Item
              label={
                <Tooltip
                  placement="rightBottom"
                  title="Links added here become trackable automatically."
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div>Link</div>
                    <InfoCircleOutlined style={{ paddingLeft: "3px" }} />
                  </div>
                </Tooltip>
              }
              style={{ width: "100%" }}
              name="contentLinkToTrackableLink"
            >
              <Input className="standard-input" placeholder="Enter Link" />
            </Form.Item>
            {/* } */}
            <div
              style={{
                position: "absolute",
                right: 0,
                bottom: 0,
                left: 0,
                width: "auto",
                borderTop: "1px solid #e9e9e9",
                fontSize: "18px",
                padding: "10px 16px",
                background: "#EFF4F9",
                textAlign: "right",
                display: "flex",
              }}
            >
              <Button
                className="standard-button tertiary-button"
                style={{ marginRight: 8, width: "48%" }}
                size="middle"
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                style={{ width: "48%" }}
                htmlType="submit"
                className="standard-button primary-orange-button"
                size="middle"
                loading={touchpointLoading}
              >
                Save
              </Button>
            </div>
          </Form>
        </Drawer>
      </div>
      <Modal
        className="journey-test-modal"
        visible={open}
        title={
          <span
            style={{
              fontSize: "18px",
              fontWeight: "600",
              lineHeight: "22px",
              color: "#252525",
            }}
          >
            Choose Contact
          </span>
        }
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <p
            style={{
              textAlign: "start",
              fontSize: "16px",
              fontWeight: "500",
              lineHeight: "19px",
              color: "#959595",
              marginBottom: "10px",
            }}
          >
            You’re about to start this contact on a journey partway through,
            which may trigger future messages.{" "}
            <span style={{ color: "#252525" }}>Click send</span> to confirm.
          </p>,
          <Button
            key="1"
            type={selectedContacts.length > 0 ? "primary" : ""}
            className="standard-button primary-orange-button"
            style={{
              width: "100%",
            }}
            onClick={() => {
              handleOk();
            }}
            disabled={selectedContacts.length <= 0}
          >
            Send
          </Button>,
        ]}
        centered
      >
        <div>
          <Search
            type="search"
            className="standard-searchbox"
            allowClear
            prefix={<SearchOutlined className="standard-searchbox-prefix" />}
            placeholder="Search Contact Name"
            style={{ display: "block", height: "42px" }}
            onChange={(e) => SearchContact(e.target.value)}
          />
        </div>
        <div style={{ height: "40vh", overflow: "scroll" }}>
          {fuzzySearchLoader ? (
            <Row gutter={[0, 16]} style={{ padding: "20px 0px" }}>
              <Col span={24}>
                <Skeleton.Avatar
                  active
                  size="default"
                  shape="circle"
                  style={{ marginRight: "10px" }}
                />
                <Skeleton.Button
                  active
                  size="default"
                  style={{ width: "200px" }}
                />
              </Col>
              <Col span={24}>
                <Skeleton.Avatar
                  active
                  size="default"
                  shape="circle"
                  style={{ marginRight: "10px" }}
                />
                <Skeleton.Button
                  active
                  size="default"
                  style={{ width: "200px" }}
                />
              </Col>
              <Col span={24}>
                <Skeleton.Avatar
                  active
                  size="default"
                  shape="circle"
                  style={{ marginRight: "10px" }}
                />
                <Skeleton.Button
                  active
                  size="default"
                  style={{ width: "200px" }}
                />
              </Col>
              <Col span={24}>
                <Skeleton.Avatar
                  active
                  size="default"
                  shape="circle"
                  style={{ marginRight: "10px" }}
                />
                <Skeleton.Button
                  active
                  size="default"
                  style={{ width: "200px" }}
                />
              </Col>
            </Row>
          ) : (
            <Row gutter={[0, 16]} style={{ padding: "20px 0px" }}>
              {searchedContacts?.map((contact) => {
                return (
                  <Col span={24} style={{ padding: "0px" }}>
                    <Checkbox
                      value={contact?.value}
                      onChange={(event) => checkedContacts(event.target.value)}
                      checked={selectedContacts?.includes(contact?.value)}
                    >
                      {contact?.label}
                    </Checkbox>
                  </Col>
                );
              })}
            </Row>
          )}
        </div>
      </Modal>
      <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
        centered
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "30px 40px",
            gap: "22px",
          }}
        >
          <img src={successIcon} alt="" />
          <p
            style={{
              fontWeight: "600",
              fontSize: "22px",
              lineHeight: "30px",
              color: "#252525",
            }}
          >
            Touchpoint Tested Successfully !
          </p>
        </div>
      </Modal>
    </>
  );
}
