/* eslint-disable array-callback-return */
import React, { Component } from "react";
import {
  Layout,
  Button,
  Space,
  Dropdown,
  Menu,
  Popconfirm,
  Skeleton,
  Row,
  Col,
  Typography,
  Modal,
  Tooltip,
  message,
  Badge,
} from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { Link, NavLink, withRouter } from "react-router-dom";
import DropDownMenu from "commons/DropDownMenu";
import { API, Auth, graphqlOperation } from "aws-amplify";
import { connect } from "react-redux";
import {
  logout,
  updateCompanyAccount,
  UpdateCompanyMetaData,
  updateUserGroups,
  setAccountDetails,
  setAccountStatus,
  setRoAdmin,
  setShopifyProfileId,
  setVerificationDetails,
} from "store/actions/loginAction";
import {
  getTnC,
  setTncShow,
  getPrivacyPolicy,
  setPrShow,
  setCompanyData,
} from "store/actions/SettingAction";
import SubMenu from "antd/lib/menu/SubMenu";
import { SliderMenu } from "utility/context/Menu";
import Title from "antd/lib/typography/Title";
import logo from "assets/Images/logo-white.svg";
import logoShort from "assets/Images/logo_short.svg";
import sidebarImage from "./../assets/Images/sidebarImage.svg";
import concentTextImage from "./../assets/Images/concentTextImage.svg";
import welcomeToBlustream from "./../assets/Images/welcomeToBlustream.svg";
import userIcon from "./../assets/Images/userIcon.svg";
import groupIcon from "./../assets/Images/groupIcon.svg";
import glowingBulbIcon from "./../assets/Images/glowingBulbIcon.svg";
import extension from "./../assets/Images/extension.gif";
import "./Layout.css";
import * as QuickSightEmbedding from "amazon-quicksight-embedding-sdk";
import {
  onCreateCompanyInviteHistory,
  onCreatePrivacyPolicy,
  onCreateTermsAndConditions,
  onDeleteCompanyInviteHistory,
  onUpdateTermsAndConditions,
} from "graphql/subscriptions";
import sdk from "sdk/Accounts";
import linkPortal from "sdk/Checkout";
import { listDelayStartAccounts } from "graphql/queries";
import TextArea from "antd/lib/input/TextArea";
import {
  deleteCompanyInviteHistory,
  updateShopifyProfile,
} from "graphql/mutations";
import { withTranslation } from "react-i18next";
const { Text } = Typography;
const VERSION = process.env.REACT_APP_VERSION
  ? process.env.REACT_APP_VERSION
  : "0.1.0";

const { Header, Sider, Content } = Layout;
class MainLayout extends Component {
  constructor(props) {
    super(props);
    const location = this.props.location.pathname;
    const { accountDetails } = this.props;
    this.state = {
      collapsed: false,
      selectedKey: SliderMenu.filter((menus) => menus.path === location)[0]
        ? SliderMenu.filter((menus) => menus.path === location)[0].key
        : "",
      loader: true,
      dashbaordURL: "",
      acceptTnCModal: false,
      tnc: undefined,
      createSubscription: undefined,
      updateSubscription: undefined,
      createPrivacyPolicySubscription: undefined,
      createInviteHistorySubscription: undefined,
      deleteInviteHistorySubscription: undefined,
      sliderMenu: [],
      companyData: [],
      loadingCompanyInfo: false,
      openDropdown: false,
      lateStartDate: "",
      shopifyProfile: {},
      concentTextAvailable: false,
      concentTitleValue: "Text me an interactive setup guide for my purchase.",
      concentTextValue: `By clicking this box, you consent to receive marketing text messages from ${accountDetails?.company} via an automatic telephone dialing system and that the number you have provided is your own. Consent is not a condition of any purchase. Standard message and data rates may apply. At any time, you can unsubscribe from all messages by replying STOP. View our Privacy Policy and Terms of Service.`,
      isNextDisabled: true,
      showCheckoutGuide: false,
      storeName: "",
      showWelcomeModal: false,
      concentSavingLoader: false,
      showReadMore: false,
      inviteHistory: null,
      showInvitationModal: false,
      showDeclineInviteModal: false,
      invitedOrganizationDetails: null,
      declineInvitationLoader: false,
      acceptInvitationLoader: false,
      verificationDetails: {},
      shopify_plan_details: null,
    };
  }

  findLateStartDate = async (accountId) => {
    const lateDate = await API.graphql(
      graphqlOperation(listDelayStartAccounts)
    );
    const filteredLateBilling =
      lateDate.data.listDelayStartAccounts.items.filter(
        (obj) => obj?.account === accountId
      );
    if (filteredLateBilling.length > 0) {
      const lateDate = new Date(
        filteredLateBilling[0]?.expirationUnixTime * 1000
      );
      var monthName = lateDate.toLocaleString("default", { month: "short" });
      this.setState({
        lateStartDate: `${monthName} ${lateDate.getDate()} ${lateDate.getFullYear()}`,
      });
    } else {
      this.setState({ lateStartDate: "" });
    }
  };

  async componentDidMount() {
    const {
      accountDetails,
      groups,
      admin_groups_self,
      shopifyProfileId,
      userData,
    } = this.props;
    if (this.props.location.pathname === "/") {
      document.getElementById("quicksight").style.display = "block";
    } else {
      document.getElementById("quicksight").style.display = "none";
    }
    if (
      groups?.includes("admin") ||
      !(
        accountDetails?.accountStatus === "PENDING_PAYMENT" ||
        accountDetails?.accountStatus === "CREATED"
      )
    )
      this.embed();
    this.setState({ sliderMenu: SliderMenu });
    const onlyCompanyGroups = admin_groups_self?.filter(
      (item) => item?.length === 36
    );
    await Promise.all(
      onlyCompanyGroups.map((item) => sdk.fecthCompanies(item))
    ).then((res) => {
      this.props.setAccountStatus(
        res[0]?.data?.getCustomerAccount?.accountStatus
      );
      this.props.setCompanyData(res);
    });
    document.addEventListener("click", this.globalClickListener);
    window.addEventListener("resize", this.showReadMoreButton);
    this.getInviteHistory(userData?.email);
    this.findLateStartDate(groups[0]);
    const apiName = "blustreamREST";
    try {
      const profilePath =
        "/shopify-intergration/get-shopify-profile-attach-with-company";
      const profileBody = {
        companyId: accountDetails?.id,
      };
      const profileResponse = await API.post(apiName, profilePath, {
        body: profileBody,
      });
      this.props.setShopifyProfileId({
        ...shopifyProfileId,
        shopifyProfileId: JSON.parse(profileResponse.body)?.shopifyprofiles[0]
          ?.id,
        email: userData?.email,
        ...profileResponse,
      });
      let path = "/shopify-intergration/shopify-profile";
      let body = {
        shopifyProfileId: JSON.parse(profileResponse.body)?.shopifyprofiles[0]
          ?.id,
      };
      const response = await API.post(apiName, path, { body: body });
      this.props.setVerificationDetails({
        is_shopify_store_linked: true,
        is_user_authorized: true,
        shopify_profile: response?.getShopifyProfile,
      });
      if (response?.getShopifyProfile?.plan_details) {
        this.setState({
          shopify_plan_details: JSON.parse(
            response?.getShopifyProfile?.plan_details
          )?.recurring_application_charge?.confirmation_url,
        });
      }
      if (response?.getShopifyProfile?.isProfileLock) {
        this.props.setShopifyProfileId({
          ...shopifyProfileId,
          ...JSON.parse(profileResponse.body)?.shopifyprofiles[0],
          shopifyProfileId: JSON.parse(profileResponse.body)?.shopifyprofiles[0]
            ?.id,
          isProfileLock: response?.getShopifyProfile?.isProfileLock,
        });
      } else {
        this.props.setShopifyProfileId({
          ...shopifyProfileId,
          ...JSON.parse(profileResponse.body)?.shopifyprofiles[0],
          shopifyProfileId: JSON.parse(profileResponse.body)?.shopifyprofiles[0]
            ?.id,
          plan_details: JSON.parse(response?.getShopifyProfile?.plan_details),
        });
      }
      if (
        !response?.getShopifyProfile?.showSteper &&
        !response?.getShopifyProfile?.isProfileLock
      ) {
        if (response?.getShopifyProfile?.showWelcomePopUp) {
          this.setState({
            showWelcomeModal: true,
          });
        }
        this.setState({
          shopifyProfile: response?.getShopifyProfile,
        });
        this.setState({
          storeName: response?.getShopifyProfile?.store_name.split(".")[0],
        });
      } else {
        if (response?.getShopifyProfile?.isProfileLock) {
          this.props.history.push("/unboxing-tour");
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  getInviteHistory = async (email) => {
    try {
      const response = await sdk.listInviteHistory(email);
      const sortedHistory =
        response?.data?.listCompanyInviteHistories?.items?.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
      this.setState({
        inviteHistory: sortedHistory,
      });
      if (sortedHistory?.length > 0) {
        const accountData = await sdk.fetchAccountDetailsOfInvitedUser(
          sortedHistory?.[0]?.account
        );
        this.setState({
          invitedOrganizationDetails: accountData,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  acceptInvitation = async (id) => {
    const { userData } = this.props;
    try {
      const apiName = "blustreamREST";
      const path = "/accounts/join-organisation";
      const body = {
        id: id,
      };
      await API.post(apiName, path, { body: body });
      this.getInviteHistory(userData?.email);
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const currentSession = await Auth.currentSession();
      cognitoUser.refreshSession(
        currentSession.refreshToken,
        (err, session) => {
          const { accessToken } = session;
          if ("cognito:groups" in accessToken.payload) {
            const response = Auth.currentUserInfo();
            response
              .then((data) => {
                this.props.updateUserGroups(session, data?.attributes);
              })
              .then((_) => {
                window.location.reload();
              });
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  declineInvitation = async (id) => {
    const { userData } = this.props;
    this.setState({
      declineInvitationLoader: true,
    });
    try {
      await API.graphql(
        graphqlOperation(deleteCompanyInviteHistory, { input: { id: id } })
      );
      this.getInviteHistory(userData?.email);
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({
        declineInvitationLoader: false,
      });
    }
  };

  checkToShowTncModal(TnC) {
    const { groups } = this.props;
    if (!groups?.includes("admin")) {
      let TnCLastUpddatedAt = TnC?.updatedAt;
      if (this.props.accountDetails?.metadata) {
        let accountMetaData = JSON.parse(this.props.accountDetails?.metadata);
        if (accountMetaData?.TnCAcceptedAt < TnCLastUpddatedAt) {
          this.setState({
            acceptTnCModal: true,
          });
        }
      } else {
        this.setState({
          acceptTnCModal: true,
        });
      }
    }
  }

  async componentWillMount() {
    const { groups, userData } = this.props;
    if (groups?.length > 0) {
      let accountData = await sdk.fetchAccount(groups[0]);
      if (accountData) {
        this.props.setAccountDetails(accountData);
      }
      this.showReadMoreButton();
    }
    const createInviteHistorySubscription = API.graphql(
      graphqlOperation(onCreateCompanyInviteHistory)
    ).subscribe({
      next: () => {
        this.getInviteHistory(userData?.email);
      },
      error: (error) => console.warn(error),
    });

    const deleteInviteHistorySubscription = API.graphql(
      graphqlOperation(onDeleteCompanyInviteHistory)
    ).subscribe({
      next: () => {
        this.getInviteHistory(userData?.email);
      },
      error: (error) => console.warn(error),
    });

    const createSubscription = API.graphql(
      graphqlOperation(onCreateTermsAndConditions)
    ).subscribe({
      next: () => {
        this.props
          .getTnC()
          .then((res) => {
            if (res?.data?.listTermsAndConditions?.items?.length > 0) {
              this.setState({
                tnc: res?.data?.listTermsAndConditions?.items?.[0],
              });
              this.checkToShowTncModal(
                res?.data?.listTermsAndConditions?.items?.[0]
              );
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
      error: (error) => console.warn(error),
    });

    const createPrivacyPolicySubscription = API.graphql(
      graphqlOperation(onCreatePrivacyPolicy)
    ).subscribe({
      next: () => {
        this.props
          .getPrivacyPolicy()
          .then((res) => {})
          .catch((err) => {
            console.log(err);
          });
      },
      error: (error) => console.warn(error),
    });

    const updateSubscription = API.graphql(
      graphqlOperation(onUpdateTermsAndConditions)
    ).subscribe({
      next: ({ provider, value }) => {
        this.props
          .getTnC()
          .then((res) => {
            if (res?.data?.listTermsAndConditions?.items?.length > 0) {
              this.setState({
                tnc: res?.data?.listTermsAndConditions?.items?.[0],
              });
              this.checkToShowTncModal(
                res?.data?.listTermsAndConditions?.items?.[0]
              );
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
      error: (error) => console.warn(error),
    });
    this.setState({
      updateSubscription,
      createSubscription,
      createPrivacyPolicySubscription,
      deleteInviteHistorySubscription,
      createInviteHistorySubscription,
    });
    this.props
      .getTnC()
      .then((res) => {
        if (res?.data?.listTermsAndConditions?.items?.length > 0) {
          this.setState({
            tnc: res?.data?.listTermsAndConditions?.items?.[0],
          });
          this.checkToShowTncModal(
            res?.data?.listTermsAndConditions?.items?.[0]
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
    this.props.getPrivacyPolicy();

    this.unlisten = this.props.history.listen((location, action) => {
      if (location.pathname === "/") {
        document.getElementById("quicksight").style.display = "block";
      } else {
        document.getElementById("quicksight").style.display = "none";
      }
      let key;
      if (location?.pathname === "/") {
        key = SliderMenu[0];
      } else {
        key = SliderMenu.slice(1)?.find((menus) => {
          if (!menus.isSubMenu) {
            if (location?.pathname?.includes(menus.path)) {
              return menus;
            }
          } else {
            let submenu = menus.subMenu.find((submenu) =>
              location?.pathname?.includes(submenu.path)
            );
            if (submenu) return submenu;
          }
        });
      }
      try {
        key && Object.keys(key).length > 0
          ? this.handleChangeKey(key.key)
          : this.handleChangeKey("");
      } catch (e) {}
    });
  }

  componentWillUnmount() {
    const {
      createSubscription,
      updateSubscription,
      createPrivacyPolicySubscription,
      deleteInviteHistorySubscription,
      createInviteHistorySubscription,
    } = this.state;
    this.unlisten();
    createSubscription?.unsubscribe();
    updateSubscription?.unsubscribe();
    createPrivacyPolicySubscription?.unsubscribe();
    deleteInviteHistorySubscription?.unsubscribe();
    createInviteHistorySubscription?.unsubscribe();
    document.removeEventListener("click", this.globalClickListener);
    window.removeEventListener("resize", this.showReadMoreButton);
  }

  handleCollapsed = () => {
    if (!this.state.collapsed) {
      document.getElementById("site-layout").style.marginLeft = "80px";
    } else {
      document.getElementById("site-layout").style.marginLeft = "250px";
    }
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  handleSignOut = async () => {
    Auth.signOut()
      .then((data) => {
        this.props.logOut();
      })
      .catch((err) => console.log(err));
  };

  handleChangeKey = (key) => {
    this.setState({
      selectedKey: key,
    });
  };

  switchAccountSelf = async (account) => {
    try {
      this.setState({ loadingCompanyInfo: true });
      await this.props.updateCompanyAccount({ id: account[0] });
      if (account[0]) {
        let accountData = await sdk.fetchAccount(account[0]);
        this.props.setAccountDetails(accountData);
      }
      setTimeout(() => {
        window.location.href = "/";
      }, 500);
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ loadingCompanyInfo: false });
    }
  };

  setLoader(value) {
    this.setState({
      loader: value,
    });
  }

  acceptTermsAndConditions(time) {
    let metaData = {
      TnCAcceptedAt: time,
    };
    this.props.UpdateCompanyMetaData(metaData).then(() => {
      this.setState({
        acceptTnCModal: false,
      });
    });
    this.props.setTncShow(true);
  }

  acceptPrivacyPolicy(time) {
    let metaData = {
      TnCAcceptedAt: time,
    };
    this.props.UpdateCompanyMetaData(metaData).then(() => {
      this.setState({
        acceptTnCModal: false,
      });
    });
  }

  dashboardloadCallback(e) {
    e.setState({
      loader: false,
    });
  }

  dashboardErrorCallback(e) {
    e.setState({
      loader: false,
    });
  }

  embed = async () => {
    try {
      const apiURL = `/getDashboardURL?scoppedID=${this.props.groups[0]}`;
      let res = await API.get("blustreamREST", apiURL).catch((error) => {
        console.log(error.response);
        this?.setLoader(false);
      });
      this.setState({
        dashbaordURL: res?.EmbedUrl,
      });
      let quicksight = document.getElementById("quicksight");
      if (quicksight) {
        quicksight.innerHTML = "";
      }
      const options = {
        url: res?.EmbedUrl,
        container: document.getElementById("quicksight"),
        scrolling: "yes",
        height: "100%",
        width: "100%",
        locale: "en-US",
        footerPaddingEnabled: true,
        undoRedoDisabled: true,
        resetDisabled: true,
        loadCallback: () => this.dashboardloadCallback(this),
        errorCallback: () => this.dashboardErrorCallback(this),
      };

      QuickSightEmbedding.embedDashboard(options);
      this.setState({
        loader: false,
      });
    } catch (error) {
      console.log(error);
    }
  };

  handleHere = async (id) => {
    const { accountDetails } = this.props;
    this.setState({ loadingCompanyInfo: true });
    if (
      accountDetails?.source === "shopify" &&
      this.state.shopify_plan_details
    ) {
      window.location.href = this.state.shopify_plan_details;
      this.setState({ loadingCompanyInfo: false });
    } else {
      await linkPortal
        .getPortalLink(id)
        .then((res) => {
          window.location.href = res?.url;
          this.setState({ loadingCompanyInfo: false });
        })
        .catch((error) => {
          this.setState({ loadingCompanyInfo: false });
          console.log(error);
        });
    }
  };

  update(nextState) {
    this.setState(nextState);
  }

  globalClickListener = () => {
    this.setState({ openDropdown: false });
  };

  showReadMoreButton = () => {
    const banner = document.getElementsByClassName("account-status-banner")[0];
    this.setState({ showReadMore: banner?.offsetWidth < banner?.scrollWidth });
  };

  render() {
    const { t } = this.props;
    const { collapsed, selectedKey, loader } = this.state;
    const {
      groups,
      selectedCompanyName,
      admin_groups_self,
      accountDetails,
      termAndConditions,
      privacyPolicy,
      accountStatus,
      roAdmin,
      shopifyProfileId,
    } = this.props;
    return (
      <>
        {this.state.loadingCompanyInfo && (
          <div className="loading">Loading;</div>
        )}
        {/* <PlanIntroductionModal
          visible={
            !groups?.includes("admin") &&
            (accountStatus === "null" || accountStatus === "CREATED") &&
            this.props.location.pathname !== "/settings" &&
            this.props.location.pathname !== "/unboxing-tour"
          }
          onContinue={() => {
            this.props.history.push("/settings");
          }}
        /> */}

        {/* for decline invite */}
        <Modal
          centered
          visible={this.state.showDeclineInviteModal}
          footer={null}
          className="decline-invite-modal"
          onCancel={() => {
            this.setState({ showDeclineInviteModal: false });
          }}
        >
          <div className="decline-invite-modal-content-wrapper">
            <img src={glowingBulbIcon} alt="" />
            <p className="decline-invite-confirmation-text">
              Are you sure you want to decline this invitation to join the
              organization?
            </p>
            <div className="decline-invite-buttons-container">
              <Button
                className="standard-button tertiary-button"
                onClick={() => {
                  this.setState({ showDeclineInviteModal: false });
                }}
              >
                No
              </Button>
              <Button
                loading={this.state.declineInvitationLoader}
                className="standard-button primary-orange-button"
                type="primary"
                onClick={async () => {
                  await this.declineInvitation(
                    this.state?.inviteHistory?.[0]?.id
                  );
                  this.setState({ showDeclineInviteModal: false });
                }}
              >
                Yes, Sure
              </Button>
            </div>
          </div>
        </Modal>
        {/* invite user modal */}
        <Modal
          title={null}
          visible={this.state.showInvitationModal}
          footer={null}
          className="invitation-modal"
          centered
          onCancel={() => this.setState({ showInvitationModal: false })}
        >
          <div className="invitation-modal-content-wrapper">
            <img src={groupIcon} alt="groupIcon" />
            <p className="invitation-title">
              {`${this.state?.inviteHistory?.[0]?.invitedby}
                 has invited you to join`}
            </p>
            <p className="company-name">
              {this.state?.invitedOrganizationDetails?.company}
            </p>

            <div className="invitation-buttons-container">
              <Button
                loading={this.state?.acceptInvitationLoader}
                className="standard-button primary-orange-button"
                onClick={async () => {
                  this.setState({
                    acceptInvitationLoader: true,
                  });
                  await this.acceptInvitation(
                    this.state?.inviteHistory?.[0]?.id
                  );
                  this.setState({
                    acceptInvitationLoader: false,
                  });
                  this.setState({ showInvitationModal: false });
                }}
              >
                Accept
              </Button>
              <Button
                loading={this.state.declineInvitationLoader}
                className="standard-button tertiary-button"
                onClick={async () => {
                  this.setState({ showDeclineInviteModal: true });
                  this.setState({ showInvitationModal: false });
                }}
              >
                Decline
              </Button>
            </div>
          </div>
        </Modal>

        <Modal
          title={null}
          visible={this.state.showWelcomeModal}
          footer={null}
          className="welcome-to-blustream-modal"
          centered
          closable={false}
        >
          <div className="welcome-to-blustream-modal-content">
            <img
              className="welcome-image"
              src={welcomeToBlustream}
              alt="welcomeToBlustreamImage"
            />
            <Typography.Text className="welcome-text">
              Welcome to Blustream!
            </Typography.Text>
            <Typography.Text className="welcome-text-2">
              Let’s review your brand new product journeys.
            </Typography.Text>

            <Button
              className="standard-button primary-orange-button"
              type="primary"
              onClick={async () => {
                this.setState({ showWelcomeModal: false });
                try {
                  await API.graphql(
                    graphqlOperation(updateShopifyProfile, {
                      input: {
                        id: shopifyProfileId?.shopifyProfileId,
                        showWelcomePopUp: false,
                      },
                    })
                  );
                } catch (error) {
                  console.log(error);
                }
              }}
            >
              Continue
            </Button>
          </div>
        </Modal>

        <Modal
          title={null}
          visible={this.state.concentTextAvailable}
          footer={null}
          className="concent-text-modal"
          centered
          closable={false}
          onCancel={() => this.setState({ concentTextAvailable: false })}
        >
          <div className="concent-text-modal-content">
            <Typography.Text className="concent-text-title">
              Please add consent text for your customers
            </Typography.Text>
            <Row gutter={28}>
              <Col span={12}>
                <img
                  className="concent-text-image"
                  src={concentTextImage}
                  alt="concentTextImage"
                />
              </Col>
              <Col span={12} className="second-column">
                <Typography.Text className="tcpa-text">
                  Under TCPA and several other state-level laws, contacts need
                  to opt in to messages. Don’t worry, we’ve got you covered.
                </Typography.Text>
                <a
                  href="https://www.fcc.gov/general/telemarketing-and-robocalls"
                  className="tcpa-link"
                  target="_blank"
                  rel="noreferrer"
                >
                  Learn more
                </a>
                <Typography.Text className="notice-text">
                  This consent text will be shown to your customers when they
                  checkout as shown in left screenshot
                </Typography.Text>
                <TextArea
                  className="concent-title-area"
                  placeholder="Add title (this will appear beside checkmark)"
                  value={this.state.concentTitleValue}
                  onChange={(event) =>
                    this.setState({ concentTitleValue: event.target.value })
                  }
                ></TextArea>
                <TextArea
                  className="concent-text-area"
                  placeholder="Add your consent text here"
                  value={this.state.concentTextValue}
                  onChange={(event) =>
                    this.setState({ concentTextValue: event.target.value })
                  }
                ></TextArea>
                <div
                  className="buttons-wrapper"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Tooltip
                    title={
                      !this.state.concentTextValue
                        ? "Please enter the concent text!"
                        : ""
                    }
                  >
                    <Button
                      type="primary"
                      disabled={
                        !this.state.concentTextValue ||
                        this.state.concentSavingLoader
                      }
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "12px 20px",
                        gap: "10px",
                        height: "40px",
                        borderRadius: "8px",
                        fontFamily: "Hellix",
                        fontStyle: "normal",
                        fontWeight: "600",
                        fontSize: "16px",
                        lineHeight: "19px",
                        borderColor: "#f37064",
                        background: "#f37064",
                        color: "#ffffff",
                      }}
                      onClick={async () => {
                        this.setState({ concentSavingLoader: true });
                        try {
                          const response = await API.graphql(
                            graphqlOperation(updateShopifyProfile, {
                              input: {
                                id: shopifyProfileId?.shopifyProfileId,
                                concent: this.state.concentTextValue,
                                concentTitle: this.state.concentTitleValue,
                              },
                            })
                          );
                          if (response.statusCode === 200) {
                            message.success("Concent Text Saved successfully!");
                            this.setState({ isNextDisabled: false });
                          } else {
                            message.error(
                              "Something went wrong, please try again!."
                            );
                          }
                        } catch (error) {
                          console.log(error);
                          message.error(
                            "Something went wrong, please try again!."
                          );
                        } finally {
                          this.setState({
                            concentSavingLoader: false,
                            isNextDisabled: false,
                          });
                        }
                      }}
                    >
                      {this.state.concentSavingLoader ? "Saving..." : "Save"}
                    </Button>
                  </Tooltip>
                  <Tooltip
                    title={
                      this.state.isNextDisabled
                        ? "Please Save the concent text first!"
                        : ""
                    }
                  >
                    <Button
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "12px 20px",
                        gap: "10px",
                        height: "40px",
                        borderRadius: "8px",
                        fontFamily: "Hellix",
                        fontStyle: "normal",
                        fontWeight: "600",
                        fontSize: "16px",
                        lineHeight: "19px",
                        background: "none",
                        color: "#959595",
                        border: "1px solid #959595",
                      }}
                      onClick={() => {
                        this.setState({ concentTextAvailable: false });
                        this.setState({ showCheckoutGuide: true });
                      }}
                      disabled={this.state.isNextDisabled}
                    >
                      Next
                    </Button>
                  </Tooltip>
                </div>
              </Col>
            </Row>
          </div>
        </Modal>

        <Modal
          title={null}
          visible={this.state.showCheckoutGuide}
          footer={null}
          className="checkout-extension-modal"
          centered
          onCancel={() => this.setState({ showCheckoutGuide: false })}
        >
          <div className="checkout-extension-modal-content">
            <Typography.Text className="checkout-extension-title">
              Install the Blustream checkout extention in your Shopify store as
              guided
            </Typography.Text>
            <img
              className="extension-gif"
              src={extension}
              alt="extension.gif"
            />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <a
                className="shopify-button"
                rel="noreferrer"
                href={`https://admin.shopify.com/store/${this.state.storeName}/settings/checkout/editor?extensionPicker=true`}
                target="_blank"
                onClick={() => this.setState({ showCheckoutGuide: false })}
              >
                Go to your Shopify store
              </a>
            </div>
          </div>
        </Modal>

        <div id="blustream-container" className="App">
          <Layout>
            <Sider
              theme="light"
              breakpoint="lg"
              className="blustream-sider"
              trigger={null}
              collapsible
              collapsed={collapsed}
              width={250}
              style={{
                height: "100vh",
                position: "fixed",
                background: "#124173",
              }}
              onBreakpoint={(broken) => {}}
              onCollapse={(Collapsed, type) => {
                this.setState({ collapsed: Collapsed });
              }}
            >
              <div className="blustream-sider-menu">
                <div style={{ marginTop: "18px" }}>
                  <div
                    className="company_logo"
                    style={{
                      height: "auto",
                      marginTop: "10px",
                      marginBottom: "20px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <NavLink to="/">
                      <img
                        src={!collapsed ? logo : logoShort}
                        height={30}
                        alt="Blustream Logo"
                        style={{ cursor: "pointer" }}
                      />
                    </NavLink>
                  </div>

                  <div
                    style={{
                      height: "calc(100vh - 70px)",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <Menu
                      className="blustream-sidebar-menu"
                      mode="inline"
                      theme="dark"
                      defaultSelectedKeys={selectedKey}
                      style={{ marginTop: "15px", backgroundColor: "#124173" }}
                      onSelect={(p) => {
                        this.handleChangeKey(p.key);
                      }}
                      selectedKeys={selectedKey}
                      defaultOpenKeys={["manage"]}
                    >
                      {this.state.sliderMenu.map((item) => {
                        const {
                          key,
                          title,
                          icon,
                          path,
                          isSubMenu,
                          onlyAdminAccess,
                        } = item;
                        return groups && groups.includes("admin") ? (
                          isSubMenu ? (
                            <SubMenu
                              key={key}
                              className="subMenuItem"
                              icon={icon}
                              selectedKeys={selectedKey === key}
                              title={title}
                              onClick={() => this.handleChangeKey(key)}
                            >
                              {item.subMenu.map((subItem) => (
                                <Menu.Item key={subItem.key}>
                                  <Link to={`${subItem.path}`}>
                                    {subItem.title}
                                  </Link>
                                </Menu.Item>
                              ))}
                            </SubMenu>
                          ) : (
                            !(roAdmin && title === "Support") && (
                              <Menu.Item key={key} icon={icon} id={key}>
                                <Link to={path}>{t(`sidebar.${title}`)}</Link>
                              </Menu.Item>
                            )
                          )
                        ) : (
                          !onlyAdminAccess &&
                            (isSubMenu ? (
                              <SubMenu
                                key={key}
                                className="subMenuItem"
                                icon={icon}
                                selectedKeys={selectedKey === key}
                                title={title}
                                onClick={() => this.handleChangeKey(key)}
                              >
                                {item.subMenu.map((subItem, index) => (
                                  <Menu.Item key={subItem.key}>
                                    <Link to={`${subItem.path}`}>
                                      {subItem.title}
                                    </Link>
                                  </Menu.Item>
                                ))}
                              </SubMenu>
                            ) : (
                              <Menu.Item key={key} icon={icon} id={key}>
                                <Link to={path} style={{ color: "#fff" }}>
                                  {title}
                                </Link>
                              </Menu.Item>
                            ))
                        );
                      })}
                    </Menu>

                    <div
                      style={{
                        lineHeight: "20px",
                        textAlign: "center",
                        padding: 5,
                        display: "flex",
                        justifyContent: "center",
                        position: "relative",
                      }}
                    >
                      <img
                        src={sidebarImage}
                        alt=""
                        style={{
                          position: "absolute",
                          bottom: "0",
                          left: "0",
                          zIndex: "-1",
                          width: "99%",
                        }}
                      ></img>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ color: "white" }}>Version {VERSION}</div>
                        <div>
                          <a
                            href={termAndConditions?.url}
                            style={{ color: "white", fontSize: "12px" }}
                            onClick={() =>
                              this.acceptTermsAndConditions(
                                termAndConditions?.updatedAt
                              )
                            }
                            title="TermAndConditions"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Terms & Conditions
                          </a>
                          <span style={{ color: "white", fontSize: "12px" }}>
                            &nbsp; | &nbsp;
                          </span>
                          <a
                            href={privacyPolicy?.url}
                            style={{ color: "white", fontSize: "12px" }}
                            onClick={() =>
                              this.acceptPrivacyPolicy(privacyPolicy?.updatedAt)
                            }
                            title="PrivacyPolicy"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Privacy Policy
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Sider>

            <Layout
              id="site-layout"
              className={collapsed ? "layout" : "layoutCollapsed"}
            >
              <Header className="site-layout-background" style={{ padding: 0 }}>
                <div
                  className="header-container"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    backgroundColor:
                      groups.includes("admin") && selectedCompanyName
                        ? window.location.hostname === "app.blustream.io"
                          ? "#E24B4C"
                          : window.location.hostname ===
                            "dev.newsaas.blustream.io"
                          ? "#E24B4C"
                          : "white"
                        : "white",
                  }}
                >
                  <span style={{ display: "inline-block", maxWidth: "180px" }}>
                    <span
                      onClick={() => this.handleCollapsed(!collapsed)}
                      className="trigger"
                    >
                      {collapsed ? (
                        <MenuUnfoldOutlined />
                      ) : (
                        <MenuFoldOutlined />
                      )}
                    </span>
                    {groups && groups.includes("admin") && (
                      <span className="admin-title">Blustream Admin</span>
                    )}
                  </span>
                  {accountStatus === "CREATED" ? (
                    <span
                      className="header-banner-container"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        this.props.history.push("/settings");
                      }}
                    >
                      <Text
                        className="account-status-banner"
                        style={{
                          padding: `12px ${
                            this.state.showReadMore ? "0px" : "12px"
                          } 12px 12px`,
                          borderRadius: `4px ${
                            this.state.showReadMore ? "0px" : "4px"
                          } ${this.state.showReadMore ? "0px" : "4px"} 4px`,
                          maxWidth: "960px",
                        }}
                        ellipsis={{
                          rows: 1,
                        }}
                      >
                        Select a suitable plan & setup payment details to
                        complete the signup process
                      </Text>
                      {this.state.showReadMore ? (
                        <Tooltip
                          placement="bottom"
                          title="Select a suitable plan & setup payment details to
                          complete the signup process"
                          getPopupContainer={(triggerNode) =>
                            document.getElementsByClassName(
                              "header-container"
                            )[0]
                          }
                        >
                          <Text className="show-more-button">Show More</Text>
                        </Tooltip>
                      ) : null}
                    </span>
                  ) : null}
                  {accountStatus === "PENDING_PAYMENT" ? (
                    <span className="header-banner-container">
                      <Text
                        className="account-status-banner"
                        style={{
                          padding: `12px ${
                            this.state.showReadMore ? "0px" : "12px"
                          } 12px 12px`,
                          borderRadius: `4px ${
                            this.state.showReadMore ? "0px" : "4px"
                          } ${this.state.showReadMore ? "0px" : "4px"} 4px`,
                          maxWidth: "960px",
                        }}
                        ellipsis={{
                          rows: 1,
                        }}
                      >
                        There has been an issue with your recent payment. Please
                        review billing details{" "}
                        <span
                          style={{
                            textDecoration: "underline",
                            color: "#124173",
                            cursor: "pointer",
                          }}
                          onClick={() => this.handleHere(accountDetails?.id)}
                        >
                          here
                        </span>
                      </Text>
                      {this.state.showReadMore ? (
                        <Tooltip
                          placement="bottom"
                          title={
                            <span>
                              There has been an issue with your recent payment.
                              Please review billing details{" "}
                              <span
                                style={{
                                  textDecoration: "underline",
                                  color: "#124173",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  this.handleHere(accountDetails?.id)
                                }
                              >
                                here
                              </span>
                            </span>
                          }
                          getPopupContainer={(triggerNode) =>
                            document.getElementsByClassName(
                              "header-container"
                            )[0]
                          }
                        >
                          <Text className="show-more-button">Show More</Text>
                        </Tooltip>
                      ) : null}
                    </span>
                  ) : null}
                  {accountStatus === "SUSPENDED" ? (
                    <span className="header-banner-container">
                      <Text
                        className="account-status-banner"
                        style={{
                          padding: `12px ${
                            this.state.showReadMore ? "0px" : "12px"
                          } 12px 12px`,
                          borderRadius: `4px ${
                            this.state.showReadMore ? "0px" : "4px"
                          } ${this.state.showReadMore ? "0px" : "4px"} 4px`,
                          maxWidth: "960px",
                        }}
                        ellipsis={{
                          rows: 1,
                        }}
                      >
                        Your account is Suspended! Please check if your payment
                        is complete or reach out to Blustream support!
                      </Text>
                      {this.state.showReadMore ? (
                        <Tooltip
                          placement="bottom"
                          title="Your account is Suspended! Please check if your payment is complete or reach out to Blustream support!"
                          getPopupContainer={(triggerNode) =>
                            document.getElementsByClassName(
                              "header-container"
                            )[0]
                          }
                        >
                          <Text className="show-more-button">Show More</Text>
                        </Tooltip>
                      ) : null}
                    </span>
                  ) : null}
                  {accountStatus === "LATE_START" ? (
                    <span
                      className="header-banner-container"
                      style={{ cursor: "pointer" }}
                      onClick={() => this.handleHere(accountDetails?.id)}
                    >
                      <Text
                        className="account-status-banner"
                        style={{
                          padding: `12px ${
                            this.state.showReadMore ? "0px" : "12px"
                          } 12px 12px`,
                          borderRadius: `4px ${
                            this.state.showReadMore ? "0px" : "4px"
                          } ${this.state.showReadMore ? "0px" : "4px"} 4px`,
                          maxWidth: "960px",
                        }}
                        ellipsis={{
                          rows: 1,
                        }}
                      >
                        {`Your billing period starts on ${this.state.lateStartDate}. To ensure messaging isn’t interrupted when we start, please add your credit card details.`}
                      </Text>
                      {this.state.showReadMore ? (
                        <Tooltip
                          placement="bottom"
                          title={`Your billing period starts on ${this.state.lateStartDate}. To ensure messaging isn’t interrupted when we start, please add your credit card details.`}
                          getPopupContainer={(triggerNode) =>
                            document.getElementsByClassName(
                              "header-container"
                            )[0]
                          }
                        >
                          <Text className="show-more-button">Show More</Text>
                        </Tooltip>
                      ) : null}
                    </span>
                  ) : null}
                  {accountStatus === "LATE_START_END" ? (
                    <span className="header-banner-container">
                      <Text
                        className="account-status-banner"
                        style={{
                          padding: `12px ${
                            this.state.showReadMore ? "0px" : "12px"
                          } 12px 12px`,
                          borderRadius: `4px ${
                            this.state.showReadMore ? "0px" : "4px"
                          } ${this.state.showReadMore ? "0px" : "4px"} 4px`,
                          maxWidth: "960px",
                        }}
                        ellipsis={{
                          rows: 1,
                        }}
                      >
                        Your trial period has been completed. To ensure
                        messaging isn’t interrupted, please add your credit card
                        details.
                      </Text>
                      {this.state.showReadMore ? (
                        <Tooltip
                          placement="bottom"
                          title="Your trial period has been completed. To ensure
                          messaging isn’t interrupted, please add your credit card
                          details."
                          getPopupContainer={(triggerNode) =>
                            document.getElementsByClassName(
                              "header-container"
                            )[0]
                          }
                        >
                          <Text className="show-more-button">Show More</Text>
                        </Tooltip>
                      ) : null}
                    </span>
                  ) : null}
                  <span
                    style={{
                      display: "flex",
                      maxWidth: "450px",
                      alignItems: "center",
                    }}
                  >
                    {groups && groups.includes("admin") && (
                      <>
                        {selectedCompanyName && (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              padding: "0px 10px",
                              maxWidth: "350px",
                              border: "1.5px solid #DEDEDE",
                              lineHeight: "20px",
                              borderRadius: "8px",
                              height: "50px",
                              backgroundColor: "white",
                            }}
                          >
                            <strong className="back-to-admin-link">
                              Viewing as {roAdmin ? "RO Admin" : "Admin"}{" "}
                              <span style={{ color: "#F5786D" }}>
                                {`(${selectedCompanyName})`}
                              </span>
                            </strong>
                            <span>
                              <Popconfirm
                                placement="bottomRight"
                                title={
                                  <Title
                                    level={5}
                                    style={{ padding: "10px 10px 0 0" }}
                                  >
                                    Do you want to switch to your profile?
                                  </Title>
                                }
                                onConfirm={() => {
                                  this.switchAccountSelf(admin_groups_self);
                                  this.props.setRoAdmin(false);
                                }}
                                okText="Yes"
                                cancelText="No"
                                icon={""}
                                okType=""
                                okButtonProps={{
                                  size: "middle",
                                  type: "primary",
                                }}
                                cancelButtonProps={{
                                  size: "middle",
                                  type: "secondary",
                                }}
                              >
                                <strong
                                  style={{
                                    paddingLeft: 8,
                                    display: "inline-block",
                                    color: "#A9A9A9",
                                  }}
                                >
                                  <CloseOutlined />
                                </strong>
                              </Popconfirm>
                            </span>
                          </div>
                        )}
                      </>
                    )}
                    <div style={{ paddingRight: "24px", paddingLeft: "14px" }}>
                      <Space>
                        <Dropdown
                          visible={this.state.openDropdown}
                          overlay={
                            <DropDownMenu
                              handleSignOut={this.handleSignOut}
                              acceptInvitation={this.acceptInvitation}
                              getInviteHistory={this.getInviteHistory}
                              path={this.props?.location?.pathname}
                              closeDropdown={() =>
                                this.setState({ openDropdown: false })
                              }
                              inviteHistory={this.state.inviteHistory}
                              invitedOrganizationDetails={
                                this.state.invitedOrganizationDetails
                              }
                              showDeclineInviteModal={() => {
                                this.setState({ showDeclineInviteModal: true });
                              }}
                              showInvitationModal={() => {
                                this.setState({ showInvitationModal: true });
                              }}
                            />
                          }
                          trigger={["click"]}
                          placement={"bottomLeft"}
                        >
                          <Badge
                            dot={this.state?.inviteHistory?.length > 0}
                            className="profile-badge"
                          >
                            <img
                              src={userIcon}
                              alt="user-icon"
                              style={{ cursor: "pointer" }}
                              onClick={(event) => {
                                event.stopPropagation();
                                this.setState({ openDropdown: true });
                              }}
                            />
                          </Badge>
                        </Dropdown>
                      </Space>
                    </div>
                  </span>
                </div>
              </Header>

              <Content
                style={{
                  padding:
                    this.props.location.pathname !== "/" &&
                    this.props.location.pathname !== "/touchpoints" &&
                    this.props.location.pathname !== "/touchpoints/workflow"
                      ? "24px 21px"
                      : "",
                  minWidth: "100%",
                  background: "#EFF4F9",
                  minHeight: "calc(100vh - 64px)",
                }}
              >
                <div className="wrapperBox" id="quicksight">
                  {loader ? (
                    <div className="dashboard-loader">
                      <Row gutter={[20, 20]} style={{ width: "100%" }}>
                        <Col>
                          <Skeleton.Button active />
                        </Col>
                        <Col>
                          <Skeleton.Button active />
                        </Col>
                        <Col>
                          <Skeleton.Button active />
                        </Col>
                        <Col>
                          <Skeleton.Button active />
                        </Col>
                      </Row>
                      <Row
                        gutter={[20, 20]}
                        style={{ width: "100%", paddingTop: 20 }}
                      >
                        <Col span={8}>
                          <Skeleton.Button
                            className="dashboard-chart-skeleton"
                            active
                          />
                        </Col>
                        <Col span={8}>
                          <Skeleton.Button
                            className="dashboard-chart-skeleton"
                            active
                          />
                        </Col>
                        <Col span={8}>
                          <Skeleton.Button
                            className="dashboard-chart-skeleton"
                            active
                          />
                        </Col>
                      </Row>
                      <Row style={{ paddingTop: 20 }}>
                        <Skeleton active paragraph={{ rows: 4 }} />
                      </Row>
                      <Row style={{ paddingTop: 20 }}>
                        <Skeleton active paragraph={{ rows: 4 }} />
                      </Row>
                    </div>
                  ) : (
                    <div className="iframeBox" />
                  )}
                </div>

                {this.props.children}
              </Content>
            </Layout>
          </Layout>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    groups,
    selectedCompanyName,
    admin_groups_self,
    accountDetails,
    accountStatus,
    roAdmin,
    shopifyProfileId,
    userData,
    verificationDetails,
  } = state.loginReducer;
  const {
    termAndConditions,
    termAndConditionsShown,
    privacyPolicy,
    privacyPolicyShown,
    companyData,
  } = state.SettingReducer;
  return {
    prop: state.prop,
    groups,
    selectedCompanyName,
    admin_groups_self,
    accountDetails,
    termAndConditions,
    termAndConditionsShown,
    privacyPolicy,
    privacyPolicyShown,
    companyData,
    accountStatus,
    roAdmin,
    shopifyProfileId,
    userData,
    verificationDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logOut: () => dispatch(logout()),
    getTnC: () => dispatch(getTnC()),
    getPrivacyPolicy: () => dispatch(getPrivacyPolicy()),
    setTncShow: (metaData) => dispatch(setTncShow(metaData)),
    setPrShow: (metaData) => dispatch(setPrShow(metaData)),
    setCompanyData: (companyData) => dispatch(setCompanyData(companyData)),
    UpdateCompanyMetaData: (metaData) =>
      dispatch(UpdateCompanyMetaData(metaData)),
    updateCompanyAccount: (account) => dispatch(updateCompanyAccount(account)),
    setAccountDetails: (account) => dispatch(setAccountDetails(account)),
    setAccountStatus: (account) => dispatch(setAccountStatus(account)),
    setRoAdmin: (bool) => dispatch(setRoAdmin(bool)),
    setShopifyProfileId: (profileData) =>
      dispatch(setShopifyProfileId(profileData)),
    updateUserGroups: (session, data) =>
      dispatch(updateUserGroups(session, data)),
    setVerificationDetails: (data) => dispatch(setVerificationDetails(data)),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation("translations")(MainLayout))
);
