/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Input,
  Modal,
  Row,
  Col,
  Drawer,
  message,
  Select,
  Skeleton,
  Typography,
  Pagination,
  Empty,
  Badge,
} from "antd";
import { SearchOutlined, SyncOutlined } from "@ant-design/icons";
import {
  getAllContacts,
  getContactProductRelations,
  createContact,
  AddProduct,
  getContactProductAttributeName,
  getDataClassInstanceId,
  getDataClassInstanceAttributeId,
  AddDataClassInstanceAttributeId,
  getDataClassInstanceAttributeValue,
  getCustomerId,
  fuzzySearch,
  getContactbyID,
  deleteContactProductRelation,
} from "apiService/RESTApi";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import glowingBulbIcon from "./../../assets/Images/glowingBulbIcon.svg";
import boxIcon from "./../../assets/Images/boxIcon.svg";

import "./productmapping.css";

const { Search } = Input;
let fuzzySearchDebounce = "";

export default function ProductDetailPage() {
  const [form] = Form.useForm();
  const [existingCustomerForm] = Form.useForm();
  const { groups, roAdmin } = useSelector((state) => state.loginReducer);

  const [addLoading, setAddLoading] = useState(false);
  // eslint-disable-next-line
  const [addForm, setAddForm] = useState({
    firstName: "",
    lastName: "",
    emailAddress: "",
    phoneNumber: "",
  });

  const [
    addContactProductAttributeDrawer,
    setAddContactProductAttributeDrawer,
  ] = useState(false);
  const [
    addExistingContactProductAttributeDrawer,
    setAddExitsingContactProductAttributeDrawer,
  ] = useState(false);
  const location = useLocation();
  const CurrentUserID = groups[0];
  const [existingLoading, setExistingLoading] = useState(false);
  const [searchedContacts, setSearchedContacts] = useState([]);
  const [initialContacts, setInitialContacts] = useState([]);
  const [fuzzySearchValue, setFuzzySearchValue] = useState("");
  const [fuzzySearchLoading, setFuzzySearchLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const [productContacts, setProductContacts] = useState([]);
  const [filteredProductContacts, setFilteredProductContacts] = useState([]);
  const [contactSearchValue, setContactSearchValue] = useState("");
  const [contactLoading, setContactLoading] = useState(false);
  const [productContactDeleteLoading, setProductContactDeleteLoading] =
    useState(false);
  const [productAttributeLoading, setProductAttributeLoading] = useState(false);
  const [saveProductAttributesLoading, setSaveProductAttributesLoading] =
    useState(false);
  const [selectedProductContactToDelete, setSelectedProductContactToDelete] =
    useState({});
  const [showDeleteProductContactModal, setShowDeleteProductContactModal] =
    useState(false);
  const [selectedContact, setSelectedContact] = useState({});
  const [selectedProductAttributes, setSelectedProductAttributes] = useState(
    []
  );
  const [
    filteredSelectedProductAttributes,
    setFilteredSelectedProductAttributes,
  ] = useState([]);
  const [attributeSearchValue, setAttributeSearchValue] = useState("");
  const [updatedAttributes, setUpdatedAttributes] = useState([]);

  // add contact product attribute
  const showAddContactDrawer = () => {
    setAddContactProductAttributeDrawer(true);
  };
  const showExistingContactDrawer = () => {
    setAddExitsingContactProductAttributeDrawer(true);
  };
  const ContactonClose = () => {
    setAddForm({
      ...form,
      firstName: "",
      lastName: "",
      phoneNumber: "",
      emailAddress: "",
    });
    form.resetFields();
    setAddContactProductAttributeDrawer(false);
  };

  const ExistingContactonClose = () => {
    setAddExitsingContactProductAttributeDrawer(false);
    existingCustomerForm.resetFields();
  };

  const getProductAttributes = async () => {
    let temp = [];
    let getData = await getContactProductRelations(
      location?.state?.productData?.cId, null, location?.state.productData?.pid
    );
    if (getData?.response?.data !== null) {
      let data = getData?.response?.data.filter(
        (item) => item?.contactDto?.isActive !== false
      );
      let tempData = {};
      data.forEach((val) => {
        if (val?.productDto?.productId === location?.state?.productData?.pid) {
          temp.push({
            ContactName:
              val.contactDto.firstName + " " + val.contactDto.lastName,
            customerId: val.contactDto.customerDto.customerId,
            id: val.contactDto.contactId,
            fname: val.contactDto.firstName,
            lname: val.contactDto.lastName,
            primaryPhoneNumber: "00000000",
            primaryEmailAddress: "-",
          });
          tempData[val.contactDto.firstName + " " + val.contactDto.lastName] =
            [];
        }
      });
      let tempContactId = [];
      data.map((item) =>
        tempContactId.push({
          contactId: item?.contactDto?.contactId,
          contactName:
            item?.contactDto?.firstName + " " + item?.contactDto?.lastName,
        })
      );
      let jsonObject = temp.map(JSON.stringify);
      let uniqueSet = new Set(jsonObject);
      let uniqueArray = Array.from(uniqueSet).map(JSON.parse);
      fetchContact(uniqueArray);
    } else {
    }
  };

  const handleAdd = async () => {
    try {
      setAddLoading(true);
      let res = await createContact(form.getFieldsValue());
      if (res?.id) {
        await AddProduct(res?.id, location?.state?.productData?.pid);
        const productContacts = await getProductsContacts();
        setProductContacts(productContacts);
        setFilteredProductContacts(productContacts);
        if (!Object.keys(selectedContact)?.length > 0) {
          setSelectedContact(productContacts?.[0]);
          setProductAttributeLoading(true);
          const productAttributes = await getProductAttributesInstanceDataId(
            productContacts?.[0]
          );
          setSelectedProductAttributes(productAttributes);
          setFilteredSelectedProductAttributes(productAttributes);
          setProductAttributeLoading(false);
        }
        message.success("Contact created successfully!");
        setAddContactProductAttributeDrawer(false);

        form.resetFields();
      } else {
        message.error("Unable to create contact!");
      }
    } catch (err) {
      message.error("Something went wrong!");
    }
    setAddLoading(false);
  };

  const fetchContactProductAttributeList = async () => {
    let temp = [];
    await getContactProductAttributeName(location?.state?.productData?.cId)
      .then((res) => {
        res?.response?.data?.map((value) =>
          temp.push({
            title: value?.name,
            dataClassAttributeId: value?.dataClassAttributeId,
          })
        );
      })
      .catch((error) => {
        message.error("Something went wrong!");
        console.log(error);
      });
  };

  const fetchContact = async (item) => {
    let response = getAllContacts(CurrentUserID);

    response
      .then((data) => {
        if (data?.response?.error) {
          message.error("Something went Wrong");
        } else {
          let usersData = data?.response?.data;
          if (usersData && usersData?.length > 0) {
            let tempArray = [];
            item.map((value) => tempArray.push(value.id));
            let temp = usersData.filter(
              (word) => !tempArray.includes(word["contactId"])
            );
            const defaultContacts = temp?.map((contact) => {
              return {
                value: contact?.contactId,
                label: `${contact?.firstName} ${contact?.lastName}`,
              };
            });
            defaultContacts.unshift({
              value: "all",
              label: "All",
            });
            setSearchedContacts(defaultContacts);
            setInitialContacts(defaultContacts);
            usersData?.map((item) => ({
              customerId: item?.customerId,
              id: item?.contactId,
              fname: item?.firstName,
              lname: item?.lastName,
              primaryPhoneNumber: item?.primaryPhoneNumber
                ? item?.primaryPhoneNumber
                : "-",
              primaryEmailAddress: item?.primaryEmailAddress
                ? item?.primaryEmailAddress
                : "-",
            }));
          }
        }
      })
      .catch((error) => {
        console.log("Error :", error);
      });
  };

  useEffect(() => {
    getAllData();
    // eslint-disable-next-line
  }, []);

  const getAllData = async () => {
    await fetchContactProductAttributeList();
    await getProductAttributes();
  };

  const handleAddExitisingCustomer = async (option) => {
    setExistingLoading(true);
    await Promise.all(
      option?.stages.map((item) =>
        AddProduct(item, location?.state?.productData?.pid)
      )
    )
      .then(async (res) => {
        const productContacts = await getProductsContacts();
        setProductContacts(productContacts);
        setFilteredProductContacts(productContacts);
        if (!Object.keys(selectedContact)?.length > 0) {
          setSelectedContact(productContacts?.[0]);
          setProductAttributeLoading(true);
          const productAttributes = await getProductAttributesInstanceDataId(
            productContacts?.[0]
          );
          setSelectedProductAttributes(productAttributes);
          setFilteredSelectedProductAttributes(productAttributes);
          setProductAttributeLoading(false);
        }
        setAddExitsingContactProductAttributeDrawer(false);
        existingCustomerForm.resetFields();
        message.success("Contact Added successfully!");
        setExistingLoading(false);
      })
      .catch((error) => console.log(error));
  };

  const handleAll = (e) => {
    if (e.includes("all")) {
      let temp = [];
      e.forEach((item) => {
        temp.push(item);
      });
      initialContacts.forEach((item) => {
        temp.push(item.value);
      });
      var filteredArray = temp.filter((e) => e !== "all");
      const finalArray = filteredArray.filter(
        (item, index) => filteredArray.indexOf(item) === index
      );
      let data = {
        stages: finalArray,
      };
      existingCustomerForm.setFieldsValue(data);
    }
  };

  useEffect(() => {
    handleExistingContactSearch();
  }, [fuzzySearchValue]);

  useEffect(() => {
    getProductsContactsAndAttributes();
  }, []);

  useEffect(() => {
    if (productContacts?.length > 0) {
      if (contactSearchValue?.length > 0) {
        const filteredContacts = productContacts?.filter((item) => {
          return item?.ContactName?.toLowerCase().includes(
            contactSearchValue?.toLowerCase()
          );
        });
        setFilteredProductContacts(filteredContacts);
      } else {
        setFilteredProductContacts(productContacts);
      }
    }
  }, [productContacts]);

  useEffect(() => {
    setUpdatedAttributes([]);
  }, [selectedContact]);

  const handleExistingContactSearch = async () => {
    if (fuzzySearchValue !== "") {
      clearTimeout(fuzzySearchDebounce);
      fuzzySearchDebounce = setTimeout(async () => {
        setFuzzySearchLoading(true);
        await getCustomerId(groups[0]).then(async (res) => {
          const customerArn = res.response.data[0].customerArn;
          const searchResults = await fuzzySearch(
            customerArn,
            fuzzySearchValue
          );
          const promiseArray = searchResults?.data?.map((contact) => {
            return getContactbyID(contact?.contactId);
          });
          Promise.all(promiseArray).then((values) => {
            const searchedResults = values?.map((contact) => {
              return {
                value: contact?.contactId,
                label: `${contact?.firstName} ${contact?.lastName}`,
              };
            });

            setSearchedContacts(searchedResults);
            setFuzzySearchLoading(false);
          });
        });
      }, 1000);
    } else {
      clearTimeout(fuzzySearchDebounce);
      setSearchedContacts(initialContacts);
    }
  };

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <p>{`< Prev`}</p>;
    }
    if (type === "next") {
      return <p>{`Next >`}</p>;
    }
    return originalElement;
  };

  const contactProductAttributePageChange = (currentPage) => {
    setCurrentPage(currentPage);
  };

  const getProductsContacts = async () => {
    try {
      const result = await getContactProductRelations(
        location?.state?.productData?.cId, null, location?.state.productData?.pid
      );
      const contactProductRelations = result?.response?.data;
      if (contactProductRelations !== null) {
        const productContacts = [];
        //filtered all active contacts
        const ActiveContacts = contactProductRelations?.filter(
          (item) => item?.contactDto?.isActive !== false
        );
        ActiveContacts?.forEach((item) => {
          if (
            item?.productDto?.productId === location?.state?.productData?.pid &&
            !productContacts?.find(
              (val) => val?.id === item?.contactDto?.contactId
            )
          ) {
            productContacts.push({
              ContactName:
                item?.contactDto?.firstName + " " + item?.contactDto?.lastName,
              customerId: item?.contactDto?.customerDto?.customerId,
              id: item?.contactDto?.contactId,
              contactProductId: item?.contactProductId,
              fname: item?.contactDto?.firstName,
              lname: item?.contactDto?.lastName,
              primaryPhoneNumber: "00000000",
              primaryEmailAddress: "-",
            });
          }
        });
        return productContacts;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getProductAttributesInstanceDataId = async (contact) => {
    let finalData = {};
    await getDataClassInstanceId(
      contact?.id,
      location?.state?.productData?.pid
    ).then(async (res) => {
      let dataClassInstanceId =
        res?.response?.data?.[0]?.dataClassInstanceDto?.dataClassInstanceId;
      if (dataClassInstanceId !== undefined) {
        let fetchAttributeId = await getDataClassInstanceAttributeId(
          dataClassInstanceId
        );
        let temp = [];
        let dataClassInstanceAttributeIds =
          fetchAttributeId?.response?.data?.map((item) =>
            temp.push({
              dataClassInstanceAttributeId: item?.dataClassInstanceAttributeId,
              dataClassAttributeId:
                item?.dataClassAttributeDto?.dataClassAttributeId,
            })
          );
        if (dataClassInstanceAttributeIds !== undefined) {
          await Promise.all(
            temp.map((item) =>
              getDataClassInstanceAttributeValue(
                item?.dataClassInstanceAttributeId
              )
            )
          )
            .then((res) => {
              let responseData = res.map((response, index) => {
                let dataObj = {};
                let productAttributeId = temp[index]["dataClassAttributeId"];

                if (productAttributeId) {
                  dataObj = {
                    productAttributeId,
                    productAttributeName:
                      response?.response?.data?.[0]
                        ?.dataClassInstanceAttributeDto?.dataClassAttributeDto
                        ?.name,
                    value:
                      response?.response?.data?.[0]?.value !== undefined
                        ? response?.response?.data?.[0]?.value
                        : null,
                    dataClassInstanceAttributeId:
                      temp[index]["dataClassInstanceAttributeId"],
                  };
                } else {
                  dataObj = {
                    productAttributeId,
                    productAttributeName:
                      response?.response?.data?.[0]
                        ?.dataClassInstanceAttributeDto?.dataClassAttributeDto
                        ?.name,
                    value:
                      response?.response?.data?.[0]?.value !== undefined
                        ? response?.response?.data?.[0]?.value
                        : null,
                    dataClassInstanceAttributeId:
                      temp[index]["dataClassInstanceAttributeId"],
                  };
                }
                return dataObj;
              });
              finalData = responseData;
            })
            .catch((error) => console.log(error));
        }
      }
    });
    return finalData;
  };

  //to get all contacts of selected products
  const getProductsContactsAndAttributes = async () => {
    try {
      setContactLoading(true);
      setProductAttributeLoading(true);
      const productContacts = await getProductsContacts();
      if (productContacts?.length > 0) {
        setProductContacts(productContacts);
        setSelectedContact(productContacts?.[0]);
        setContactLoading(false);
        const productAttributes = await getProductAttributesInstanceDataId(
          productContacts?.[0]
        );
        setSelectedProductAttributes(productAttributes);
        setFilteredSelectedProductAttributes(productAttributes);
        setProductAttributeLoading(false);
      } else {
        //this is for the case when no contacts for the products
        setProductContacts([]);
        setFilteredProductContacts([]);
        setSelectedContact({});
        setSelectedProductAttributes([]);
        setFilteredSelectedProductAttributes([]);
        setContactLoading(false);
        setProductAttributeLoading(false);
      }
    } catch (error) {
      console.log(error);
      setContactLoading(false);
      setProductAttributeLoading(false);
    }
  };

  //to search contacts
  const searchContact = (value) => {
    if (value?.length > 0) {
      const filteredContacts = productContacts?.filter((item) => {
        return item?.ContactName?.toLowerCase().includes(
          value?.trimStart()?.toLowerCase()
        );
      });
      setFilteredProductContacts(filteredContacts);
    } else {
      setFilteredProductContacts(productContacts);
    }
  };

  //to delete the contact product relation
  const deleteContactFromProduct = async () => {
    try {
      setProductContactDeleteLoading(true);
      await deleteContactProductRelation(
        selectedProductContactToDelete?.contactProductId
      );
      if (selectedProductContactToDelete?.id === selectedContact?.id) {
        setAttributeSearchValue("");
        await getProductsContactsAndAttributes();
        setUpdatedAttributes([]);
      } else {
        const productContacts = await getProductsContacts();
        setProductContacts(productContacts);
        setFilteredProductContacts(productContacts);
      }
      setProductContactDeleteLoading(false);
      setShowDeleteProductContactModal(false);
    } catch (error) {
      console.log(error);
      setProductContactDeleteLoading(false);
      setShowDeleteProductContactModal(false);
    }
  };

  const SaveProductAttributes = async () => {
    try {
      if (updatedAttributes?.length > 0) {
        setSaveProductAttributesLoading(true);
        var requestPending = updatedAttributes?.length;
        updatedAttributes.forEach((item) => {
          AddDataClassInstanceAttributeId(
            item?.dataClassInstanceAttributeId,
            item?.value
          ).then(() => {
            requestPending--;
            if (requestPending === 0) {
              setSaveProductAttributesLoading(false);
              message.success("Product attributes are saved successfully!");
            }
          });
        });
        setUpdatedAttributes([]);
      }
    } catch (error) {
      console.log(error);
      message.error("Something went wrong!");
    }
  };

  return (
    <>
      <Row className="product-detail-page-container" justify="center">
        <Col span={22} className="product-detail-content-wrapper">
          <div className="product-name-container">
            <img src={boxIcon} alt="box-icon" className="box-icon" />
            <h2 className="product-name">
              {location?.state?.productData?.pname}
            </h2>
          </div>
          <div className="product-details-contact-product-attributes-container">
            <div className="attributes-title-container">
              <p className="title">Product owners</p>
              <div className="search-button-container">
                <Search
                  className="standard-searchbox"
                  allowClear
                  prefix={
                    <SearchOutlined className="standard-searchbox-prefix" />
                  }
                  value={attributeSearchValue}
                  placeholder="Search attribute"
                  disabled={
                    productAttributeLoading ||
                    !selectedProductAttributes?.length > 0
                  }
                  onChange={(event) => {
                    setCurrentPage(1);
                    setAttributeSearchValue(event.target?.value);
                    if (event.target?.value) {
                      setFilteredSelectedProductAttributes(
                        selectedProductAttributes?.filter((item) => {
                          return item?.productAttributeName
                            ?.toLowerCase()
                            ?.includes(
                              event.target?.value?.trimStart()?.toLowerCase()
                            );
                        })
                      );
                    } else {
                      setFilteredSelectedProductAttributes(
                        selectedProductAttributes
                      );
                    }
                  }}
                />
                {updatedAttributes?.length > 0 ? (
                  <Badge
                    dot
                    color="#124172"
                    style={{
                      height: "8px",
                      width: "8px",
                    }}
                  >
                    {" "}
                  </Badge>
                ) : null}
                <Button
                  className="standard-button secondary-orange-button"
                  loading={saveProductAttributesLoading}
                  onClick={() => {
                    if (updatedAttributes?.length > 0) {
                      SaveProductAttributes();
                    }
                  }}
                  disabled={roAdmin}
                >
                  Save
                </Button>
                <Button
                  className="standard-button secondary-orange-button"
                  onClick={showExistingContactDrawer}
                  disabled={roAdmin}
                >
                  Add existing contact
                </Button>
                <Button
                  className="standard-button primary-orange-button"
                  onClick={showAddContactDrawer}
                  disabled={roAdmin}
                >
                  Add new contact
                </Button>
              </div>
            </div>
            <Row gutter={[20, 20]}>
              <Col span={24} xs={24} sm={24} md={8} lg={7}>
                <div className="products-contact-and-search-wrapper">
                  <Search
                    className="standard-searchbox products-contact-searchbox"
                    allowClear
                    prefix={
                      <SearchOutlined className="standard-searchbox-prefix" />
                    }
                    placeholder="Search contact"
                    disabled={!productContacts?.length > 0}
                    onChange={(event) => {
                      searchContact(event.target.value);
                      setContactSearchValue(event.target.value);
                    }}
                  />

                  <div className="products-contact-container">
                    {contactLoading ? (
                      [...Array(5).keys()].map(() => {
                        return (
                          <div className="contacts-product-detail-container">
                            <div className="product-wrapper">
                              <Skeleton.Avatar active shape="circle" />
                              <Skeleton.Input className="product-name" active />
                            </div>
                          </div>
                        );
                      })
                    ) : filteredProductContacts?.length > 0 ? (
                      filteredProductContacts?.map((item) => {
                        return (
                          <div
                            className="contacts-product-detail-container"
                            onClick={async () => {
                              setAttributeSearchValue("");
                              setProductAttributeLoading(true);
                              setCurrentPage(1);
                              setSelectedContact(item);
                              const productAttributes =
                                await getProductAttributesInstanceDataId(item);
                              setSelectedProductAttributes(productAttributes);
                              setFilteredSelectedProductAttributes(
                                productAttributes
                              );
                              setProductAttributeLoading(false);
                            }}
                          >
                            <div className="product-wrapper">
                              <Typography.Paragraph className="product-image">
                                {item?.ContactName?.slice(0, 1)?.toUpperCase()}
                              </Typography.Paragraph>
                              <Typography.Paragraph
                                className={`product-name ${
                                  selectedContact?.id === item?.id
                                    ? "highlight-product"
                                    : ""
                                }`}
                                ellipsis={{
                                  rows: 1,
                                }}
                              >
                                {item?.ContactName}
                              </Typography.Paragraph>
                            </div>

                            {selectedContact?.id === item?.id ? (
                              <div className="highlighted-bar"></div>
                            ) : null}
                          </div>
                        );
                      })
                    ) : (
                      <Empty
                        description="No contacts found!"
                        style={{
                          width: "100%",
                          minHeight: "300px",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          margin: "0px",
                        }}
                      />
                    )}
                  </div>
                </div>
              </Col>
              <Col span={24} xs={24} sm={24} md={16} lg={17}>
                <Form
                  layout="vertical"
                  className="contact-product-attribute-form"
                  requiredMark={false}
                >
                  {productAttributeLoading ? (
                    <Row gutter={[20, 40]} style={{ marginBottom: "20px" }}>
                      {[...Array(20).keys()].map(() => {
                        return (
                          <Col
                            span={24}
                            xs={24}
                            sm={24}
                            md={12}
                            lg={12}
                            xl={12}
                            xxl={8}
                          >
                            <Skeleton.Input
                              className="initial-skeleton-loader"
                              active={true}
                            />
                          </Col>
                        );
                      })}
                    </Row>
                  ) : filteredSelectedProductAttributes?.length > 0 ? (
                    <Row gutter={[20, 0]}>
                      {filteredSelectedProductAttributes
                        ?.slice(20 * (currentPage - 1), 20 * currentPage)
                        ?.map((item) => {
                          return (
                            <Col
                              span={24}
                              xs={24}
                              sm={24}
                              md={12}
                              lg={12}
                              xl={12}
                              xxl={8}
                              key={item.attributeId}
                            >
                              <Form.Item
                                label={item?.productAttributeName}
                                className="contact-attribute-form-item"
                                name={item?.dataClassInstanceAttributeId}
                                initialValue={item?.value}
                              >
                                <Input
                                  className="standard-input"
                                  disabled={roAdmin}
                                  value={item?.value}
                                  placeholder={`Enter ${item?.productAttributeName}`}
                                  onChange={(event) => {
                                    const updatedAttribute =
                                      updatedAttributes?.find(
                                        (attributeObject) =>
                                          attributeObject?.dataClassInstanceAttributeId ===
                                          item?.dataClassInstanceAttributeId
                                      );
                                    if (updatedAttribute) {
                                      setUpdatedAttributes(
                                        updatedAttributes?.map(
                                          (attributeObject) => {
                                            if (
                                              attributeObject?.dataClassInstanceAttributeId ===
                                              item?.dataClassInstanceAttributeId
                                            ) {
                                              attributeObject.value =
                                                event.target.value;
                                              return attributeObject;
                                            }
                                            return attributeObject;
                                          }
                                        )
                                      );
                                    } else {
                                      item.value = event.target.value;
                                      setUpdatedAttributes([
                                        ...updatedAttributes,
                                        item,
                                      ]);
                                    }
                                  }}
                                />
                              </Form.Item>
                            </Col>
                          );
                        })}
                    </Row>
                  ) : (
                    <Empty
                      description="No data found!"
                      style={{
                        width: "100%",
                        minHeight: "400px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    />
                  )}
                </Form>
                <div className="contact-product-attribute-pagination-container">
                  <p className="attribute-text">Showing 20 per page</p>
                  <Pagination
                    className="contact-product-attribute-pagination"
                    current={currentPage}
                    onChange={contactProductAttributePageChange}
                    total={filteredSelectedProductAttributes?.length}
                    itemRender={itemRender}
                    pageSize={20}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      {/* Add new contact product attribute drawer  */}
      <Drawer
        className="addProperty"
        name="dynamic_form_nest_item"
        width={430}
        title="Add New Contact"
        placement="right"
        onClose={ContactonClose}
        visible={addContactProductAttributeDrawer}
        maskClosable={false}
      >
        <Form
          form={form}
          layout="vertical"
          style={{ paddingBottom: 60 }}
          onFinish={handleAdd}
          requiredMark={false}
          className="add-contact-form"
        >
          <Form.Item
            label="First Name *"
            style={{ width: "100%" }}
            name="firstName"
            rules={[
              {
                required: true,
                message: "This field can not be empty!",
              },
            ]}
          >
            <Input className="standard-input" placeholder="Enter First Name" />
          </Form.Item>
          <Form.Item
            label="Last Name *"
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "This field can not be empty!",
              },
            ]}
            name="lastName"
          >
            <Input className="standard-input" placeholder="Enter Last Name" />
          </Form.Item>
          <Form.Item
            label="Phone Number *"
            style={{ width: "100%" }}
            name="phoneNumber"
            rules={[
              {
                required: true,
                message: "This field can not be empty!",
              },
            ]}
          >
            <Input
              className="standard-input"
              placeholder="Enter Phone Number"
            />
          </Form.Item>
          <Form.Item
            label="Email Address"
            style={{ width: "100%" }}
            name="emailAddress"
          >
            <Input
              className="standard-input"
              placeholder="Enter Email Address"
            />
          </Form.Item>

          <div
            style={{
              position: "absolute",
              right: 0,
              bottom: 0,
              left: 0,
              width: "auto",
              borderTop: "1px solid #e9e9e9",
              fontSize: "18px",
              padding: "10px 16px",
              background: "#EFF4F9",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <p style={{ fontSize: 14, color: "#6B7A89" }}>
              All these contacts will be added in Contact manager as well
            </p>
            <div style={{ display: "flex" }}>
              <Button
                className="standard-button tertiary-button"
                style={{ marginRight: 8, width: "48%" }}
                onClick={ContactonClose}
                disabled={addLoading}
              >
                Cancel
              </Button>
              <Button
                className="standard-button primary-orange-button"
                type="primary"
                style={{ width: "48%" }}
                htmlType="submit"
                loading={addLoading}
              >
                Add
              </Button>
            </div>
          </div>
        </Form>
      </Drawer>

      {/* Add existing contact product attribute drawer  */}
      <Drawer
        className="addProperty"
        name="dynamic_form_nest_item"
        width={430}
        title="Add Existing Contact"
        placement="right"
        onClose={ExistingContactonClose}
        visible={addExistingContactProductAttributeDrawer}
        maskClosable={false}
      >
        <Form
          form={existingCustomerForm}
          layout="vertical"
          style={{ paddingBottom: 60 }}
          onFinish={handleAddExitisingCustomer}
        >
          <Form.Item
            name="stages"
            className="existing-contact-select"
            label={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <strong style={{ fontSize: "16px" }}>
                  Add existing contact
                </strong>
                {fuzzySearchLoading ? (
                  <SyncOutlined
                    spin
                    style={{ marginLeft: "5px", fontSize: "20px" }}
                  />
                ) : null}
              </div>
            }
          >
            <Select
              mode="multiple"
              showArrow
              allowClear
              style={{ width: "100%" }}
              className="add-existing-contact-multi-select"
              placeholder="Select contacts"
              size="large"
              filterOption={false}
              onSearch={setFuzzySearchValue}
              onChange={(e) => handleAll(e)}
              options={searchedContacts}
              searchValue={fuzzySearchValue}
            />
          </Form.Item>

          <div
            style={{
              position: "absolute",
              right: 0,
              bottom: 0,
              left: 0,
              width: "auto",
              borderTop: "1px solid #e9e9e9",
              fontSize: "18px",
              padding: "10px 16px",
              background: "#EFF4F9",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div style={{ display: "flex" }}>
              <Button
                className="standard-button tertiary-button"
                style={{ marginRight: 8, width: "48%" }}
                onClick={ExistingContactonClose}
                disabled={existingLoading}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                style={{ width: "48%" }}
                htmlType="submit"
                className="standard-button primary-orange-button"
                loading={existingLoading}
              >
                Add
              </Button>
            </div>
          </div>
        </Form>
      </Drawer>

      {/* delete products contact modal */}
      <Modal
        centered
        visible={showDeleteProductContactModal}
        closable={false}
        footer={null}
        className="delete-contacts-product-modal"
      >
        <div className="delete-content-container">
          <img src={glowingBulbIcon} alt="" />
          <p className="contacts-product-delete-modal-dark-text">
            {`Are you sure that you want to remove this contact ${selectedProductContactToDelete?.ContactName} ?`}
          </p>
          <p className="contacts-product-delete-modal-light-text">
            Once deleted, all contact attributes linked with the product will
            also be removed!
          </p>
          <div className="delete-contacts-product-modal-button-wrapper">
            <Button
              className="standard-button primary-orange-button"
              type="primary"
              onClick={async () => {
                deleteContactFromProduct();
              }}
              loading={productContactDeleteLoading}
            >
              Yes, I understand
            </Button>
            <Button
              className="standard-button secondary-orange-button"
              onClick={() => setShowDeleteProductContactModal(false)}
            >
              No
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}
