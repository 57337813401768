import { React, useEffect, useState } from "react";
import {
  Col,
  Row,
  Form,
  Input,
  Button,
  Checkbox,
  Radio,
  Select,
  message,
  Tooltip,
  TreeSelect,
  Modal,
  Typography,
} from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useHistory, useParams } from "react-router-dom";
import { API } from "aws-amplify";
import { emailPattern } from "utility/Constants";
import "./MicrositeUserForm.css";
import successIcon from "../../assets/Images/successIcon.svg";
import maintenance from "../../assets/Images/maintenance.svg";
import { invertColor } from "../../utility/LightenDarkenColor";
import marked from "marked";
import useCustomBreakpoints from "utility/useCustomBreakPoints";
import axios from "axios";
import { handleImagesOnLanguageChange } from "commons/DropDownLang/HandleImageRender";
import { useTranslation } from "react-i18next";
import i18n from "i18n";

const MicrositeUserForm = () => {
  const { id } = useParams();
  const { t } = useTranslation("translations");

  const history = useHistory();

  // eslint-disable-next-line
  const [productId, setproductId] = useState(
    String(history?.location?.search).split("?productId=")[1]
  );
  const [form] = Form.useForm();
  // eslint-disable-next-line
  const [templateName, setTemplateName] = useState("");
  const [micrositeDefaultStyle, setMicrositeDefaultStyle] = useState({});
  const [micrositeFormElements, setMicrositeFormElements] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [hiddenFields, sethiddenFields] = useState({});

  const { Option } = Select;
  const { SHOW_PARENT } = TreeSelect;

  const [multiSelectValues, setmultiSelectValues] = useState([]);
  const [captchaVerified, setcaptchaVerified] = useState(false);
  const [visible, setVisible] = useState(false);
  const [accountId, setAccountId] = useState("");

  const screens = useCustomBreakpoints();

  // this is for the multiple select dropdown
  const setSelectedValues = (newValue) => {
    setmultiSelectValues(newValue);
  };

  const dropdownObj = micrositeFormElements.find((elementObj) => {
    return elementObj?.type === "multiselect-dropdown";
  });

  const tProps = {
    treeData: dropdownObj?.options,
    value: multiSelectValues,
    onChange: setSelectedValues,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: dropdownObj?.placeholder,
    showSearch: true,
    style: {
      width: "100%",
    },
  };

  const formTracker = async (
    eventName,
    accountId,
    email,
    firstName,
    lastName
  ) => {
    const res = await axios.get("https://geolocation-db.com/json/");

    let apiName = "blustreamREST";
    let path = "/shopify-intergration/notify_about_user_tracker_event";
    let body = {
      event_name: eventName,
      account_id: accountId,
      email: email,
      firstname: firstName,
      lastname: lastName,
      ipaddress: res.data.IPv4,
      products: "",
    };

    return await API.post(apiName, path, { body: body });
  };

  useEffect(() => {
    // Initialize reCAPTCHA with your site key
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(process.env.REACT_APP_CAPTCHA_SITE_KEY, {
          action: "verifiying_contact",
        })
        .then(async (token) => {
          // Send the token to your server for verification
          const apiName = "blustreamREST";
          const path = "/customerform/verify_captcha";
          const body = { token: token };

          var response = await API.post(apiName, path, { body: body });
          if (response?.success) {
            setcaptchaVerified(true);
          } else {
            message.error("Captcha verification failed, refresh the page!");
          }
        })
        .catch(() => {
          message.error("Captcha verification failed, refresh the page!");
        });
    });
    try {
      API.get("blustreamREST", `/customerform/get_microsite?id=${id}`)
        .then((response) => {
          if (response?.disabled) {
            setError("currently in progress try after sometime.");
          }
          setAccountId(response?.account);
          setTemplateName(response?.templateName);

          setMicrositeDefaultStyle(JSON.parse(response?.micrositeStyle));
          setMicrositeFormElements(JSON.parse(response?.micrositeFormElements));
          document.title = JSON.parse(
            response?.micrositeStyle
          )?.micrositePageTitle;
          i18n.changeLanguage(
            JSON.parse(response?.micrositeStyle)?.formLanguage
          );

          formTracker("Form Page Loads", response?.account, "", "", "");
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } catch (error) {
      setError("enable to Fetch form.");
      console.log(error);
      setLoading(false);
    }
    // eslint-disable-next-line
  }, []);

  function setDisposableData() {
    if (window.location.ancestorOrigins?.length > 0) {
      window.parent.postMessage(id, window.location.ancestorOrigins[0]);
    }
  }

  const handleSubmission = async (data) => {
    if (captchaVerified) {
      setLoading(true);
      const newData = { id: id, ...data, ...hiddenFields };
      const finalData = newData?.product
        ? { ...newData, product: String(newData["product"]) }
        : newData;
      let path = productId
        ? `/customerform?productId=${productId}`
        : `/customerform`;
      let apiName = "blustreamREST";
      let body = { customerDetails: finalData };
      try {
        var result = await API.post(apiName, path, { body: body });
        message.success("Form submmited successfully.");
        if (micrositeDefaultStyle?.permenantHideOnSubmit) {
          setTimeout(() => {
            setDisposableData();
          }, 2000);
        }
        setVisible(true);
        setLoading(false);
        form.resetFields();
        setInitialValues();
        formTracker(
          "Form Submitted",
          accountId,
          data?.["Email"] ? data?.["Email"] : "",
          data?.["First Name"],
          data?.["Last Name"]
        );
        return result;
      } catch (e) {
        console.log(e);
        message.error("Something went wrong.");
        setLoading(false);
        form.resetFields();
        setInitialValues();
        return { error: "Failed to send customer details." };
      }
    }
  };

  useEffect(() => {
    setInitialValues();
    // eslint-disable-next-line
  }, [micrositeFormElements]);

  const setInitialValues = () => {
    let temp = {};
    let hidden = {};
    micrositeFormElements.forEach((item) => {
      if (item?.options) {
        if (item?.isHidden) {
          if (item.attributeType === "contact-attribute") {
            if (item?.type === "multichoice") {
              hidden[String("contact.attribute." + item.attributeId)] =
                item?.styleRadioValue === "Radio"
                  ? item?.options[item?.isCheckedIndex]?.value
                  : [item?.options[item?.isCheckedIndex]?.value];
            } else {
              hidden[String("contact.attribute." + item.attributeId)] =
                item?.options[item?.isCheckedIndex].value;
            }
          } else if (item.attributeType === "product") {
            hidden["product"] = item?.options.map((obj) => obj.value).join(",");
          } else if (item.attributeType === "contact-product-attribute") {
            if (item?.type === "multichoice") {
              hidden[
                String(
                  `contact.product.attribute.${item.attributeId}:::${item.product}`
                )
              ] =
                item?.styleRadioValue === "Radio"
                  ? item?.options[item?.isCheckedIndex]?.value
                  : [item?.options[item?.isCheckedIndex]?.value];
            } else {
              hidden[
                String(
                  `contact.product.attribute.${item.attributeId}:::${item.product}`
                )
              ] = item?.options[item?.isCheckedIndex].value;
            }
          }
        } else {
          if (item.attributeType === "contact-attribute") {
            if (item?.type === "multichoice") {
              if (item?.isCheckedIndex) {
                temp[String("contact.attribute." + item.attributeId)] =
                  item?.styleRadioValue === "Radio"
                    ? item?.options[item?.isCheckedIndex]?.value
                    : [item?.options[item?.isCheckedIndex]?.value];
              }
            } else {
              temp[String("contact.attribute." + item.attributeId)] =
                item?.options[item?.isCheckedIndex]?.value;
            }
          } else if (item.attributeType === "contact-product-attribute") {
            if (item?.type === "multichoice") {
              if (item?.isCheckedIndex) {
                temp[
                  String(
                    `contact.product.attribute.${item.attributeId}:::${item.product}`
                  )
                ] =
                  item?.styleRadioValue === "Radio"
                    ? item?.options[item?.isCheckedIndex]?.value
                    : [item?.options[item?.isCheckedIndex]?.value];
              }
            } else {
              temp[
                String(
                  `contact.product.attribute.${item.attributeId}:::${item.product}`
                )
              ] = item?.options[item?.isCheckedIndex]?.value;
            }
          } else if (item.attributeType === "product") {
            temp["product"] = item?.options[item?.isCheckedIndex]?.value;
          }
        }
      } else {
        if (item?.isHidden) {
          if (item.attributeType === "contact-attribute") {
            hidden[String("contact.attribute." + item.attributeId)] =
              item?.placeholder;
          } else if (item.attributeType === "contact-product-attribute") {
            hidden[
              String(
                `contact.product.attribute.${item.attributeId}:::${item.product}`
              )
            ] = item?.placeholder;
          } else if (item.type === "default-email") {
            hidden[String("default-email")] = item?.placeholder;
          }
        }
      }
      return true;
    });
    sethiddenFields(hidden);
    form.setFieldsValue(temp);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <Modal visible={visible} onCancel={handleCancel} footer={null}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "30px 40px",
          }}
        >
          <img src={successIcon} alt="" />
          <p
            style={{ fontSize: "16px", marginTop: "20px" }}
            className="form-success-message-text"
            dangerouslySetInnerHTML={{
              __html: marked(
                micrositeDefaultStyle?.successMessage
                  ? micrositeDefaultStyle?.successMessage
                  : ""
              ),
            }}
          />
        </div>
      </Modal>
      {loading && <div className="loading">Loading;</div>}
      {error.length === 0 ? (
        <Row
          justify="center"
          style={{ height: "100vh", alignItems: "center", overflowY: "scroll" }}
        >
          <Col span={24}>
            <div className="form-body" style={{ padding: 0 }}>
              {templateName === "TWO_COLUMN_LEFT_FORM" ||
              templateName === "TWO_COLUMN_RIGHT_FORM" ? (
                <Row style={{ overflowY: "scroll" }}>
                  <Col
                    span={14}
                    lg={14}
                    md={14}
                    sm={24}
                    xs={24}
                    style={{
                      position: "relative",
                      backgroundColor:
                        templateName === "TWO_COLUMN_LEFT_FORM"
                          ? micrositeDefaultStyle?.themeStyle?.backgroundColor
                          : micrositeDefaultStyle?.themeStyle
                              ?.secondaryBackgroundColor,
                      backgroundImage: `url("${micrositeDefaultStyle?.themeStyle?.backgroundImageUrl}")`,
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: `${micrositeDefaultStyle?.themeStyle?.backgroundWidth}% ${micrositeDefaultStyle?.themeStyle?.backgroundHeight}%`,
                    }}
                    order={
                      screens.xs || screens.sm || screens.md
                        ? 2
                        : templateName === "TWO_COLUMN_LEFT_FORM"
                        ? 1
                        : 2
                    }
                  >
                    <Row
                      style={
                        templateName === "TWO_COLUMN_LEFT_FORM"
                          ? {
                              minHeight: "100vh",
                              display: "flex",
                              justifyContent: "end",
                              alignItems: "center",
                              padding: "20px 50px 50px 0",
                            }
                          : {
                              minHeight: "100vh",
                              display: "flex",
                              justifyContent: "start",
                              alignItems: "center",
                              padding: "20px 0px 50px 50px",
                            }
                      }
                      className="form-row-container"
                    >
                      <Col span={15} xs={20} md={20} lg={18} xxl={13}>
                        <Form
                          layout="vertical"
                          className="dynamic-form"
                          onFinish={(data) => {
                            if (
                              micrositeDefaultStyle?.formLanguage &&
                              micrositeDefaultStyle?.formLanguage !== "en"
                            ) {
                              const updatedData = { ...data };
                              updatedData["First Name"] =
                                data?.[t(`FORMS.firstNameLabel`)];
                              delete updatedData?.[t(`FORMS.firstNameLabel`)];
                              updatedData["Last Name"] =
                                data?.[t(`FORMS.lastNameLabel`)];
                              delete updatedData?.[t(`FORMS.lastNameLabel`)];
                              updatedData["Phone No."] =
                                data?.[t(`FORMS.phoneLabel`)];
                              delete updatedData?.[t(`FORMS.phoneLabel`)];
                              updatedData["Email"] =
                                data?.[t(`FORMS.emailLabel`)];
                              delete updatedData?.[t(`FORMS.emailLabel`)];
                              handleSubmission(updatedData);
                            } else {
                              handleSubmission(data);
                            }
                          }}
                          form={form}
                        >
                          <Row gutter={[10, 10]}>
                            {micrositeFormElements?.map(
                              (currentFormElement) => {
                                return (
                                  <>
                                    {currentFormElement?.type === "image" && (
                                      <Col
                                        id={currentFormElement?.id}
                                        span={24}
                                      >
                                        <img
                                          src={micrositeDefaultStyle?.logoUrl}
                                          style={{
                                            ...currentFormElement?.style,
                                            height:
                                              micrositeDefaultStyle?.logoHeight
                                                ? micrositeDefaultStyle?.logoHeight
                                                : "30px",
                                            width:
                                              micrositeDefaultStyle?.logoWidth
                                                ? micrositeDefaultStyle?.logoWidth
                                                : "auto",
                                          }}
                                          alt="Your logo"
                                        />
                                      </Col>
                                    )}
                                    {currentFormElement?.type === "heading" && (
                                      <Col
                                        id={currentFormElement?.id}
                                        span={24}
                                      >
                                        <h1
                                          className="form-heading"
                                          style={{
                                            ...micrositeDefaultStyle?.headingStyle,
                                            ...currentFormElement?.style,
                                          }}
                                          dangerouslySetInnerHTML={{
                                            __html: marked(
                                              currentFormElement?.headingText
                                                ? currentFormElement?.headingText
                                                : ""
                                            ),
                                          }}
                                        />
                                      </Col>
                                    )}

                                    {currentFormElement?.type ===
                                      "sub-heading" && (
                                      <div
                                        style={{ width: "100%" }}
                                        id={currentFormElement?.id}
                                        span={24}
                                      >
                                        <h2
                                          className="form-sub-heading"
                                          style={{
                                            ...micrositeDefaultStyle?.subHeadingStyle,
                                            ...currentFormElement?.style,
                                          }}
                                          dangerouslySetInnerHTML={{
                                            __html: marked(
                                              currentFormElement?.headingText
                                                ? currentFormElement?.headingText
                                                : ""
                                            ),
                                          }}
                                        />
                                      </div>
                                    )}

                                    {currentFormElement?.type ===
                                      "default-input" && (
                                      <>
                                        <Col
                                          id={currentFormElement?.id}
                                          span={12}
                                          lg={12}
                                          md={12}
                                          sm={24}
                                          xs={24}
                                        >
                                          <Form.Item
                                            name={currentFormElement?.label}
                                            className="dynamic-form-item"
                                            label={
                                              <div
                                                style={{
                                                  display: "flex",
                                                  justifyContent:
                                                    "space-between",
                                                  alignItems: "center",
                                                  minWidth: "100%",
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    ...micrositeDefaultStyle?.questionStyle,
                                                  }}
                                                >
                                                  {currentFormElement?.label}
                                                </span>
                                                {currentFormElement.description ? (
                                                  <Tooltip
                                                    title={
                                                      currentFormElement.description
                                                        ? currentFormElement.description
                                                        : ""
                                                    }
                                                  >
                                                    <span>
                                                      <InfoCircleOutlined
                                                        style={{
                                                          color:
                                                            micrositeDefaultStyle
                                                              ?.questionStyle
                                                              ?.color,
                                                          fontSize: "15px",
                                                        }}
                                                      />
                                                    </span>
                                                  </Tooltip>
                                                ) : null}
                                              </div>
                                            }
                                            rules={
                                              currentFormElement?.htmlType ===
                                              "tel"
                                                ? [
                                                    {
                                                      required: true,
                                                      message: (
                                                        <span
                                                          style={{
                                                            color:
                                                              micrositeDefaultStyle?.errorMessageColor,
                                                          }}
                                                        >
                                                          Please enter{" "}
                                                          {currentFormElement?.label.toLowerCase()}
                                                          !
                                                        </span>
                                                      ),
                                                    },
                                                    {
                                                      pattern:
                                                        // /^([0|\\+[0-9]{1,3})?([0-9]{10})$/,
                                                        /^\+?([\\(\\)\s-]*\d[\\(\\)]*){10,13}$/,
                                                      message: (
                                                        <span
                                                          style={{
                                                            color:
                                                              micrositeDefaultStyle?.errorMessageColor,
                                                          }}
                                                        >
                                                          Please enter valid
                                                          phone no.!
                                                        </span>
                                                      ),
                                                    },
                                                  ]
                                                : currentFormElement?.htmlType ===
                                                  "email"
                                                ? [
                                                    {
                                                      required: true,
                                                      message: (
                                                        <span
                                                          style={{
                                                            color:
                                                              micrositeDefaultStyle?.errorMessageColor,
                                                          }}
                                                        >
                                                          Please enter{" "}
                                                          {currentFormElement?.label.toLowerCase()}
                                                          !
                                                        </span>
                                                      ),
                                                    },
                                                    {
                                                      pattern: emailPattern,
                                                      message: (
                                                        <span
                                                          style={{
                                                            color:
                                                              micrositeDefaultStyle?.errorMessageColor,
                                                          }}
                                                        >
                                                          Please enter a valid
                                                          email address!
                                                        </span>
                                                      ),
                                                    },
                                                  ]
                                                : [
                                                    {
                                                      required: true,
                                                      message: (
                                                        <span
                                                          style={{
                                                            color:
                                                              micrositeDefaultStyle?.errorMessageColor,
                                                          }}
                                                        >
                                                          Please enter{" "}
                                                          {currentFormElement?.label.toLowerCase()}
                                                          !
                                                        </span>
                                                      ),
                                                    },
                                                  ]
                                            }
                                          >
                                            <Input
                                              placeholder={
                                                currentFormElement?.placeholder
                                              }
                                              style={{
                                                ...micrositeDefaultStyle?.answerStyle,
                                                ...micrositeDefaultStyle?.fieldStyle,
                                              }}
                                            />
                                          </Form.Item>
                                        </Col>
                                      </>
                                    )}

                                    {currentFormElement?.type ===
                                      "default-email" &&
                                      !currentFormElement?.isHidden && (
                                        <>
                                          <Col
                                            id={currentFormElement?.id}
                                            span={12}
                                          >
                                            <Form.Item
                                              name={currentFormElement?.label}
                                              className="dynamic-form-item"
                                              label={
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent:
                                                      "space-between",
                                                    alignItems: "center",
                                                    minWidth: "100%",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      ...micrositeDefaultStyle?.questionStyle,
                                                    }}
                                                  >
                                                    {currentFormElement?.label}
                                                  </span>
                                                  {currentFormElement.description ? (
                                                    <Tooltip
                                                      title={
                                                        currentFormElement.description
                                                          ? currentFormElement.description
                                                          : ""
                                                      }
                                                    >
                                                      <span>
                                                        <InfoCircleOutlined
                                                          style={{
                                                            color:
                                                              micrositeDefaultStyle
                                                                ?.questionStyle
                                                                ?.color,
                                                            fontSize: "15px",
                                                          }}
                                                        />
                                                      </span>
                                                    </Tooltip>
                                                  ) : null}
                                                </div>
                                              }
                                              rules={[
                                                {
                                                  required:
                                                    currentFormElement?.isRequired,
                                                  message: (
                                                    <span
                                                      style={{
                                                        color:
                                                          micrositeDefaultStyle?.errorMessageColor,
                                                      }}
                                                    >
                                                      Please enter{" "}
                                                      {currentFormElement?.label.toLowerCase()}
                                                      !
                                                    </span>
                                                  ),
                                                },
                                                {
                                                  pattern: emailPattern,
                                                  message: (
                                                    <span
                                                      style={{
                                                        color:
                                                          micrositeDefaultStyle?.errorMessageColor,
                                                      }}
                                                    >
                                                      Please enter a valid email
                                                      address!
                                                    </span>
                                                  ),
                                                },
                                              ]}
                                            >
                                              <Input
                                                placeholder={
                                                  currentFormElement?.placeholder
                                                }
                                                style={{
                                                  ...micrositeDefaultStyle?.answerStyle,
                                                  ...micrositeDefaultStyle?.fieldStyle,
                                                }}
                                              />
                                            </Form.Item>
                                          </Col>
                                        </>
                                      )}

                                    {currentFormElement?.type === "input" &&
                                      !currentFormElement?.isHidden && (
                                        <>
                                          <Col
                                            id={currentFormElement?.id}
                                            span={12}
                                            lg={12}
                                            md={12}
                                            sm={24}
                                            xs={24}
                                          >
                                            <Form.Item
                                              name={String(
                                                currentFormElement?.attributeType ===
                                                  "contact-attribute"
                                                  ? `contact.attribute.${currentFormElement?.attributeId}`
                                                  : `contact.product.attribute.${currentFormElement?.attributeId}:::${currentFormElement?.product}`
                                              )}
                                              className="dynamic-form-item"
                                              label={
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent:
                                                      "space-between",
                                                    alignItems: "center",
                                                    minWidth: "100%",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      ...micrositeDefaultStyle?.questionStyle,
                                                    }}
                                                  >
                                                    {currentFormElement?.label}
                                                  </span>
                                                  {currentFormElement.description ? (
                                                    <Tooltip
                                                      title={
                                                        currentFormElement.description
                                                          ? currentFormElement.description
                                                          : ""
                                                      }
                                                    >
                                                      <span>
                                                        <InfoCircleOutlined
                                                          style={{
                                                            color:
                                                              micrositeDefaultStyle
                                                                ?.questionStyle
                                                                ?.color,
                                                            fontSize: "15px",
                                                          }}
                                                        />
                                                      </span>
                                                    </Tooltip>
                                                  ) : null}
                                                </div>
                                              }
                                              rules={[
                                                {
                                                  required:
                                                    currentFormElement?.isRequired,
                                                  message: (
                                                    <span
                                                      style={{
                                                        color:
                                                          micrositeDefaultStyle?.errorMessageColor,
                                                      }}
                                                    >
                                                      Please enter{" "}
                                                      {currentFormElement?.label.toLowerCase()}
                                                      !
                                                    </span>
                                                  ),
                                                },

                                                currentFormElement?.htmlType ===
                                                "number"
                                                  ? () => ({
                                                      validator(_, value) {
                                                        if (!isNaN(value)) {
                                                          return Promise.resolve();
                                                        }

                                                        return Promise.reject(
                                                          <span
                                                            style={{
                                                              color:
                                                                micrositeDefaultStyle?.errorMessageColor,
                                                            }}
                                                          >
                                                            only numbers are
                                                            acceptable!
                                                          </span>
                                                        );
                                                      },
                                                    })
                                                  : currentFormElement?.htmlType ===
                                                    "email"
                                                  ? {
                                                      pattern: emailPattern,
                                                      message: (
                                                        <span
                                                          style={{
                                                            color:
                                                              micrositeDefaultStyle?.errorMessageColor,
                                                          }}
                                                        >
                                                          Please enter a valid
                                                          email address!
                                                        </span>
                                                      ),
                                                    }
                                                  : currentFormElement?.htmlType ===
                                                    "url"
                                                  ? {
                                                      pattern:
                                                        /(www\.)?[-a-zA-Z0-9_]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
                                                      message: (
                                                        <span
                                                          style={{
                                                            color:
                                                              micrositeDefaultStyle?.errorMessageColor,
                                                          }}
                                                        >
                                                          Please enter valid URL
                                                        </span>
                                                      ),
                                                    }
                                                  : {},
                                              ]}
                                            >
                                              <Input
                                                placeholder={
                                                  currentFormElement?.placeholder
                                                }
                                                style={{
                                                  ...micrositeDefaultStyle?.answerStyle,
                                                  ...micrositeDefaultStyle?.fieldStyle,
                                                }}
                                              />
                                            </Form.Item>
                                          </Col>
                                        </>
                                      )}

                                    {currentFormElement?.type === "dropdown" &&
                                      !currentFormElement?.isHidden && (
                                        <>
                                          <Col
                                            id={currentFormElement?.id}
                                            span={12}
                                            lg={12}
                                            md={12}
                                            sm={24}
                                            xs={24}
                                          >
                                            <Form.Item
                                              name={String(
                                                currentFormElement?.attributeType ===
                                                  "contact-attribute"
                                                  ? `contact.attribute.${currentFormElement?.attributeId}`
                                                  : currentFormElement?.attributeType ===
                                                    "product"
                                                  ? "product"
                                                  : `contact.product.attribute.${currentFormElement?.attributeId}:::${currentFormElement?.product}`
                                              )}
                                              className="dynamic-form-item"
                                              label={
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent:
                                                      "space-between",
                                                    alignItems: "center",
                                                    minWidth: "100%",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      ...micrositeDefaultStyle?.questionStyle,
                                                    }}
                                                  >
                                                    {currentFormElement?.label}
                                                  </span>
                                                  {currentFormElement.description ? (
                                                    <Tooltip
                                                      title={
                                                        currentFormElement.description
                                                          ? currentFormElement.description
                                                          : ""
                                                      }
                                                    >
                                                      <span>
                                                        <InfoCircleOutlined
                                                          style={{
                                                            color:
                                                              micrositeDefaultStyle
                                                                ?.questionStyle
                                                                ?.color,
                                                            fontSize: "15px",
                                                          }}
                                                        />
                                                      </span>
                                                    </Tooltip>
                                                  ) : null}
                                                </div>
                                              }
                                              rules={[
                                                {
                                                  required:
                                                    currentFormElement?.isRequired
                                                      ? true
                                                      : false,
                                                  message: (
                                                    <span
                                                      style={{
                                                        color:
                                                          micrositeDefaultStyle?.errorMessageColor,
                                                      }}
                                                    >
                                                      Please select value!
                                                    </span>
                                                  ),
                                                },
                                              ]}
                                            >
                                              <Select
                                                placeholder={
                                                  currentFormElement?.placeholder
                                                }
                                                style={{ width: "100%" }}
                                                className="Opt-1 round"
                                              >
                                                {currentFormElement.options.map(
                                                  (obj, index) => {
                                                    return (
                                                      <Option
                                                        key={index}
                                                        value={obj.value}
                                                      >
                                                        {obj.label}
                                                      </Option>
                                                    );
                                                  }
                                                )}
                                              </Select>
                                            </Form.Item>
                                          </Col>
                                        </>
                                      )}

                                    {currentFormElement?.type ===
                                      "multiselect-dropdown" &&
                                      !currentFormElement?.isHidden && (
                                        <>
                                          <Col
                                            id={currentFormElement?.id}
                                            span={12}
                                            lg={12}
                                            md={12}
                                            sm={24}
                                            xs={24}
                                          >
                                            <Form.Item
                                              name="product"
                                              className="dynamic-form-item"
                                              label={
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent:
                                                      "space-between",
                                                    alignItems: "center",
                                                    minWidth: "100%",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      ...micrositeDefaultStyle?.questionStyle,
                                                    }}
                                                  >
                                                    {currentFormElement?.label}
                                                  </span>
                                                  {currentFormElement.description ? (
                                                    <Tooltip
                                                      title={
                                                        currentFormElement.description
                                                          ? currentFormElement.description
                                                          : ""
                                                      }
                                                    >
                                                      <span>
                                                        <InfoCircleOutlined
                                                          style={{
                                                            color:
                                                              micrositeDefaultStyle
                                                                ?.questionStyle
                                                                ?.color,
                                                            fontSize: "15px",
                                                          }}
                                                        />
                                                      </span>
                                                    </Tooltip>
                                                  ) : null}
                                                </div>
                                              }
                                              rules={[
                                                {
                                                  required:
                                                    currentFormElement?.isRequired
                                                      ? true
                                                      : false,
                                                  message: (
                                                    <span
                                                      style={{
                                                        color:
                                                          micrositeDefaultStyle?.errorMessageColor,
                                                      }}
                                                    >
                                                      Please select value!
                                                    </span>
                                                  ),
                                                },
                                              ]}
                                            >
                                              <TreeSelect
                                                className="tree-select"
                                                {...tProps}
                                                style={{
                                                  ...micrositeDefaultStyle?.answerStyle,
                                                  ...micrositeDefaultStyle?.fieldStyle,
                                                  border: `${micrositeDefaultStyle?.fieldStyle.borderWidth} solid ${micrositeDefaultStyle?.fieldStyle.borderColor}`,
                                                }}
                                              />
                                            </Form.Item>
                                          </Col>
                                        </>
                                      )}

                                    {currentFormElement?.type ===
                                      "multichoice" &&
                                      !currentFormElement?.isHidden && (
                                        <>
                                          <Col
                                            id={currentFormElement?.id}
                                            span={24}
                                            lg={24}
                                            md={24}
                                            sm={24}
                                            xs={24}
                                          >
                                            <Form.Item
                                              name={String(
                                                currentFormElement?.attributeType ===
                                                  "contact-attribute"
                                                  ? `contact.attribute.${currentFormElement?.attributeId}`
                                                  : `contact.product.attribute.${currentFormElement?.attributeId}:::${currentFormElement?.product}`
                                              )}
                                              className="dynamic-form-item"
                                              label={
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent:
                                                      "space-between",
                                                    alignItems: "center",
                                                    minWidth: "100%",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      ...micrositeDefaultStyle?.questionStyle,
                                                    }}
                                                  >
                                                    {currentFormElement?.label}
                                                  </span>
                                                  {currentFormElement.description ? (
                                                    <Tooltip
                                                      title={
                                                        currentFormElement.description
                                                          ? currentFormElement.description
                                                          : ""
                                                      }
                                                    >
                                                      <span>
                                                        <InfoCircleOutlined
                                                          style={{
                                                            color:
                                                              micrositeDefaultStyle
                                                                ?.questionStyle
                                                                ?.color,
                                                            fontSize: "15px",
                                                          }}
                                                        />
                                                      </span>
                                                    </Tooltip>
                                                  ) : null}
                                                </div>
                                              }
                                              rules={[
                                                {
                                                  required:
                                                    currentFormElement?.isRequired
                                                      ? true
                                                      : false,
                                                  message: (
                                                    <span
                                                      style={{
                                                        color:
                                                          micrositeDefaultStyle?.errorMessageColor,
                                                      }}
                                                    >
                                                      Please pick an item!
                                                    </span>
                                                  ),
                                                },
                                              ]}
                                            >
                                              {currentFormElement?.styleRadioValue ===
                                              "Tags" ? (
                                                <Checkbox.Group
                                                  style={{
                                                    display: "flex",
                                                    gap: "5px",
                                                    flexWrap: "wrap",
                                                  }}
                                                >
                                                  {currentFormElement?.isOther
                                                    ? [
                                                        ...currentFormElement?.options,
                                                        {
                                                          label: "Other",
                                                          value: "Other",
                                                        },
                                                      ].map((obj, index) => {
                                                        return (
                                                          <Checkbox
                                                            key={index}
                                                            value={obj.value}
                                                          >
                                                            <span
                                                              style={{
                                                                ...micrositeDefaultStyle?.answerStyle,
                                                              }}
                                                            >
                                                              {obj.label}
                                                            </span>
                                                          </Checkbox>
                                                        );
                                                      })
                                                    : currentFormElement?.options.map(
                                                        (obj, index) => {
                                                          return (
                                                            <Checkbox
                                                              key={index}
                                                              value={obj.value}
                                                            >
                                                              <span
                                                                style={{
                                                                  ...micrositeDefaultStyle?.answerStyle,
                                                                }}
                                                              >
                                                                {obj.label}
                                                              </span>
                                                            </Checkbox>
                                                          );
                                                        }
                                                      )}
                                                </Checkbox.Group>
                                              ) : (
                                                <Radio.Group
                                                  style={{
                                                    display: "flex",
                                                    gap: "5px",
                                                    flexWrap: "wrap",
                                                  }}
                                                >
                                                  {currentFormElement?.isOther
                                                    ? [
                                                        ...currentFormElement?.options,
                                                        {
                                                          label: "Other",
                                                          value: "Other",
                                                        },
                                                      ].map((obj, index) => {
                                                        return (
                                                          <Radio
                                                            key={index}
                                                            value={obj.value}
                                                          >
                                                            <span
                                                              style={{
                                                                ...micrositeDefaultStyle?.answerStyle,
                                                              }}
                                                            >
                                                              {obj.label}
                                                            </span>
                                                          </Radio>
                                                        );
                                                      })
                                                    : currentFormElement?.options.map(
                                                        (obj, index) => {
                                                          return (
                                                            <Radio
                                                              key={index}
                                                              value={obj.value}
                                                            >
                                                              <span
                                                                style={{
                                                                  ...micrositeDefaultStyle?.answerStyle,
                                                                }}
                                                              >
                                                                {obj.label}
                                                              </span>
                                                            </Radio>
                                                          );
                                                        }
                                                      )}
                                                </Radio.Group>
                                              )}
                                            </Form.Item>
                                          </Col>
                                        </>
                                      )}

                                    {currentFormElement?.type === "button" && (
                                      <>
                                        <Col
                                          id={currentFormElement?.id}
                                          span={24}
                                        >
                                          <Button
                                            htmlType="submit"
                                            className="secondary-btn"
                                            shape="round"
                                            style={{
                                              ...micrositeDefaultStyle?.buttonStyle,
                                              ...currentFormElement?.style,
                                            }}
                                          >
                                            {currentFormElement?.buttonText}
                                          </Button>
                                        </Col>
                                      </>
                                    )}
                                    {currentFormElement?.type === "text" && (
                                      <>
                                        <Col
                                          id={currentFormElement?.id}
                                          span={24}
                                        >
                                          <p
                                            style={{
                                              ...micrositeDefaultStyle?.textStyle,
                                              ...currentFormElement?.style,
                                            }}
                                          >
                                            {currentFormElement?.textValue}
                                          </p>
                                        </Col>
                                      </>
                                    )}
                                    {currentFormElement?.type ===
                                      "Consent-text" && (
                                      <>
                                        <Col
                                          id={currentFormElement?.id}
                                          span={24}
                                        >
                                          <p
                                            style={{
                                              color: "gray",
                                            }}
                                            className="form-consent-text"
                                            dangerouslySetInnerHTML={{
                                              __html: marked(
                                                currentFormElement?.textValue
                                                  ? currentFormElement?.textValue
                                                  : ""
                                              ),
                                            }}
                                          />
                                        </Col>
                                      </>
                                    )}
                                    {currentFormElement?.type ===
                                      "copyright" && (
                                      <>
                                        <Col
                                          id={currentFormElement?.id}
                                          span={24}
                                        >
                                          <p
                                            style={{
                                              ...micrositeDefaultStyle?.textStyle,
                                              ...currentFormElement?.style,
                                            }}
                                          >
                                            {currentFormElement?.textValue}
                                          </p>
                                        </Col>
                                      </>
                                    )}
                                  </>
                                );
                              }
                            )}
                          </Row>
                        </Form>
                      </Col>
                    </Row>
                  </Col>

                  <Col
                    span={10}
                    lg={10}
                    md={10}
                    sm={24}
                    xs={24}
                    style={{
                      backgroundColor:
                        templateName === "TWO_COLUMN_LEFT_FORM"
                          ? micrositeDefaultStyle?.themeStyle
                              ?.secondaryBackgroundColor
                          : micrositeDefaultStyle?.themeStyle?.backgroundColor,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                    order={
                      screens.xs || screens.sm || screens.md
                        ? 1
                        : templateName === "TWO_COLUMN_LEFT_FORM"
                        ? 2
                        : 1
                    }
                  >
                    <Row
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                      className="form-col-two-wrapper"
                    >
                      <Col
                        span={18}
                        className="form-col-two-container"
                        style={{ position: "relative" }}
                      >
                        <h3
                          className="form-company-text"
                          style={{
                            textAlign: "center",
                            padding: "20px",
                            margin: "30px 0px",
                            color: "black",
                            ...micrositeDefaultStyle?.companyTextStyle,
                          }}
                          dangerouslySetInnerHTML={{
                            __html: marked(
                              micrositeFormElements[
                                micrositeFormElements.length - 1
                              ]?.textValue
                                ? micrositeFormElements[
                                    micrositeFormElements.length - 1
                                  ]?.textValue
                                : ""
                            ),
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <img
                    src={
                      micrositeDefaultStyle?.themeStyle
                        ?.secondaryBackgroundColor
                        ? invertColor(
                            screens.xs || screens.sm || screens.md
                              ? templateName === "TWO_COLUMN_LEFT_FORM"
                                ? micrositeDefaultStyle?.themeStyle
                                    ?.backgroundColor
                                : micrositeDefaultStyle?.themeStyle
                                    ?.secondaryBackgroundColor
                              : micrositeDefaultStyle?.themeStyle
                                  ?.secondaryBackgroundColor
                          ) === "#000000"
                          ? handleImagesOnLanguageChange(
                              true,
                              micrositeDefaultStyle?.formLanguage
                            )
                          : handleImagesOnLanguageChange(
                              false,
                              micrositeDefaultStyle?.formLanguage
                            )
                        : handleImagesOnLanguageChange(
                            true,
                            micrositeDefaultStyle?.formLanguage
                          )
                    }
                    alt=""
                    style={{
                      height: "25px",
                      width: "auto",
                      position: "absolute",
                      right: "10px",
                      bottom: "10px",
                      cursor: "pointer",
                      zIndex: 10,
                    }}
                    onClick={(e) => {
                      window.open("https://www.blustream.io", "_blank");
                    }}
                  />
                </Row>
              ) : (
                <></>
              )}
              {/* //dev :- for adding new template ,don't remove this */}
              {/* {templateName === "THREE_COLUMN_LEFT_FORM" ? <h1>three column layout</h1>:<></>} */}
            </div>
          </Col>
        </Row>
      ) : (
        <Row className="form-error-page">
          <Col className="form-error-page-content">
            <img src={maintenance} alt="" />
            <Typography.Text className="form-error-page-title">
              This form is not operational as of now!
            </Typography.Text>
            <Typography.Text className="form-error-page-text">
              Please come back later. Thank you for your patience.
            </Typography.Text>
          </Col>
        </Row>
      )}
    </>
  );
};

export default MicrositeUserForm;
